// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/@wix/yoshi-style-dependencies/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".qviTE{height:100%;display:flex;flex-direction:row;text-align:unset;background-color:white}@media only screen and (max-width: 1024px){.qviTE{position:relative;top:60px}}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"marketplaceLayout": "qviTE"
};
export default ___CSS_LOADER_EXPORT___;
