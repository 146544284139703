// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/@wix/yoshi-style-dependencies/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ARMHy{margin-right:3px}.hb5m0{margin-right:3px;padding-bottom:2px}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"star-icon": "ARMHy",
	"starIcon": "ARMHy",
	"rating": "hb5m0"
};
export default ___CSS_LOADER_EXPORT___;
