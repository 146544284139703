// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/@wix/yoshi-style-dependencies/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FW3rZ{padding-right:18px !important;padding-bottom:6px !important;padding-top:6px !important}.FW3rZ:hover{color:#116DFF}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wix-offering-style": "FW3rZ",
	"wixOfferingStyle": "FW3rZ"
};
export default ___CSS_LOADER_EXPORT___;
