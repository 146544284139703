// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/@wix/yoshi-style-dependencies/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".j6bc9{display:flex;margin:12px 0}@media only screen and (max-width: 600px){.j6bc9{margin:0px}}.j6bc9 ._2I2ik{margin-right:30px}.j6bc9 .KeZnF{display:flex;width:41.66%}.j6bc9 .WpUR-{width:58%}@media only screen and (max-width: 1024px){.j6bc9{flex-direction:column}.j6bc9 ._2I2ik{margin-right:18px}.j6bc9 .KeZnF{width:-moz-fit-content;width:fit-content;margin:0 auto}.j6bc9 .WpUR-{margin:30px 0 6px 0;width:100%}}.j6bc9 .wWbFT{margin-top:42px}@media only screen and (max-width: 600px){.j6bc9 .wWbFT{margin-top:12px}}.j6bc9 .pYszC{max-height:490px;width:100%;max-width:800px;overflow:hidden}.j6bc9 .zx55w{max-height:490px;width:100%;max-width:800px}.j6bc9 .f4ehA{height:400px}.j6bc9 .a\\+Ov0{padding-top:18px;flex:1;min-width:222px;max-width:222px;width:222px}@media only screen and (max-width: 600px){.j6bc9 .a\\+Ov0{padding-top:14px}}.j6bc9 ._8Z1B9{padding-top:36px}@media only screen and (max-width: 600px){.j6bc9 ._8Z1B9{padding-top:12px}}@media only screen and (max-width: 600px){.j6bc9 ._8Z1B9 .lKRYP{font-size:24px !important;line-height:30px !important;margin-bottom:10px}}.j6bc9 ._8Z1B9 .NETbC{margin-top:12px;display:flex;flex-direction:row;margin-bottom:42px}@media only screen and (max-width: 600px){.j6bc9 ._8Z1B9 .NETbC{margin-bottom:25px}}.j6bc9 ._8Z1B9 .NETbC .dwfD6{fill:#fdb10c}.j6bc9 ._8Z1B9 .NETbC .abMh3{fill:#cbd3dc}.j6bc9 ._8Z1B9 .Z\\+7nA{display:flex;align-items:center}.j6bc9 ._8Z1B9 .Z\\+7nA ._92qeS{color:#60bc57;margin-right:3px}.j6bc9 .OXSBA{width:60px;height:60px}.j6bc9 ._8kzzn{align-self:center;padding-top:1px}.j6bc9 ._8kzzn ._6un\\+b{padding-left:2px}.j6bc9 .FAzBB{display:inline-block}.j6bc9 .cenTn{border:none}.j6bc9 ._4xLXR{margin-top:12px;display:flex;justify-content:center;flex-direction:column;align-items:center}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"infoLayout": "j6bc9",
	"avatar": "_2I2ik",
	"solutionDetails": "KeZnF",
	"solutionGallery": "WpUR-",
	"iconAvatar": "wWbFT",
	"previewContainer": "pYszC",
	"previewPreviewContainer": "zx55w",
	"solutionReview": "f4ehA",
	"infoDetailsTeaser": "a+Ov0",
	"infoDetailsContainer": "_8Z1B9",
	"infoTitle": "lKRYP",
	"userRankingContainer": "NETbC",
	"rateStar": "dwfD6",
	"rateStarGray": "abMh3",
	"installedContainer": "Z+7nA",
	"installedMark": "_92qeS",
	"solutionIcon": "OXSBA",
	"oneStarRating": "_8kzzn",
	"reviewStar": "_6un+b",
	"btnAndPricing": "FAzBB",
	"carouselVideo": "cenTn",
	"planPricing": "_4xLXR"
};
export default ___CSS_LOADER_EXPORT___;
