// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/@wix/yoshi-style-dependencies/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DgKSI{background-color:#f0f3f5;height:100%}.DgKSI .Oc1yR{padding-top:42px;padding-bottom:30px;text-align:center}.DgKSI .hsNiR{display:flex;align-items:center;border-radius:8px;margin:0 36px;padding:30px 24px;background-color:#ffffff}.DgKSI .hsNiR img{width:60px;height:60px;border-radius:50%}.DgKSI .hsNiR .QoBoz{display:flex;flex-direction:column;justify-content:center;margin-left:18px}.DgKSI .LiAzc{margin-left:auto}.DgKSI ._6Qpv3{display:inline;margin-left:18px;position:relative;top:24px}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"finishSetup": "DgKSI",
	"header": "Oc1yR",
	"appCard": "hsNiR",
	"column": "QoBoz",
	"addToSite": "LiAzc",
	"manageAppsLink": "_6Qpv3"
};
export default ___CSS_LOADER_EXPORT___;
