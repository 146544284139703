// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/@wix/yoshi-style-dependencies/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GfaL6{margin-top:18px;margin-bottom:-18px}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"backToReferrer": "GfaL6"
};
export default ___CSS_LOADER_EXPORT___;
