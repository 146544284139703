// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/@wix/yoshi-style-dependencies/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LqhEu{color:blue}.tzwW8{position:fixed;top:0;left:0;z-index:9;width:100vw;height:100vh;background:rgba(22,45,61,0.66);pointer-events:none;display:none}@media only screen and (max-width: 600px){.tzwW8{display:block}}.HSFsQ{position:fixed;top:0;left:0;transform:translateY(-100vh);height:auto;width:100vw;background-color:white;transition:transform 300ms linear;z-index:9;display:none}@media only screen and (max-width: 600px){.HSFsQ{display:block}}.HSFsQ .pZq1I{padding:20px 14px}.HSFsQ .ACfPJ{display:flex;justify-content:space-between;align-items:center;margin-bottom:18px}.HSFsQ.Z2nkF{transform:translateY(0)}.HSFsQ .NBQZ7{padding-left:26px}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"demo": "LqhEu",
	"overlay": "tzwW8",
	"mobile-search": "HSFsQ",
	"mobileSearch": "HSFsQ",
	"inner": "pZq1I",
	"top-section": "ACfPJ",
	"topSection": "ACfPJ",
	"open": "Z2nkF",
	"topics-title": "NBQZ7",
	"topicsTitle": "NBQZ7"
};
export default ___CSS_LOADER_EXPORT___;
