// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/@wix/yoshi-style-dependencies/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Bh9tH,.Si\\+ky{margin-top:-144px;padding-bottom:28px;background-color:transparent !important;min-height:760px}.Si\\+ky{background-color:#ECEFF3 !important;padding-top:18px;overflow:auto;height:100%;margin-top:0}.TeUlR{padding-top:0 !important}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"page-settings": "Bh9tH",
	"pageSettings": "Bh9tH",
	"page-settings-grey": "Si+ky",
	"pageSettingsGrey": "Si+ky",
	"no-padding": "TeUlR",
	"noPadding": "TeUlR"
};
export default ___CSS_LOADER_EXPORT___;
