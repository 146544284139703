// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/@wix/yoshi-style-dependencies/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".nD2DI{height:83px}@media only screen and (max-width: 600px){.nD2DI{height:auto}}.nD2DI .UrAd6{height:24px}.nD2DI ._3KGkg{display:flex;flex-direction:column;word-break:break-word}.nD2DI .e2LbT{max-height:36px;max-width:308px}.nD2DI .eOzXh{font-family:HelveticaNeueW01-75Bold, HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi,\"Helvetica Neue\",\"Helvetica\",\"Arial\",\"\\30E1\\30A4\\30EA\\30AA\",\"meiryo\",\"\\30D2\\30E9\\30AE\\30CE\\89D2\\30B4   pro w3\",\"hiragino kaku gothic pro\",\"sans-serif\";font-size:18px !important}.nD2DI .cI8AZ{font-size:18px !important}.nD2DI .n9Rkc{justify-content:space-between;border-radius:8px}.nD2DI .kr-Qy{margin-top:15px;margin-bottom:24px}.nD2DI .Isa4d{width:112px}.nD2DI ._1WymY{border-radius:6px;height:54px;width:54px;background-color:rgba(22,45,61,0.04);-o-object-fit:contain;object-fit:contain}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"solution-detail": "nD2DI",
	"solutionDetail": "nD2DI",
	"solution-name": "UrAd6",
	"solutionName": "UrAd6",
	"title-company": "_3KGkg",
	"titleCompany": "_3KGkg",
	"solution-teaser": "e2LbT",
	"solutionTeaser": "e2LbT",
	"solution-title": "eOzXh",
	"solutionTitle": "eOzXh",
	"madefor-solution-title": "cI8AZ",
	"madeforSolutionTitle": "cI8AZ",
	"adp-banner": "n9Rkc",
	"adpBanner": "n9Rkc",
	"adp-banner-wrapper": "kr-Qy",
	"adpBannerWrapper": "kr-Qy",
	"adp-banner-cta": "Isa4d",
	"adpBannerCta": "Isa4d",
	"adp-banner-logo": "_1WymY",
	"adpBannerLogo": "_1WymY"
};
export default ___CSS_LOADER_EXPORT___;
