// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/@wix/yoshi-style-dependencies/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._0a-Vz{display:flex;flex-direction:column;background-color:white;overflow:auto;width:calc(100% - 230px)}@media only screen and (max-width: 600px){._0a-Vz{width:100%}}._4V9X7{display:flex;flex-direction:column;background-color:white;overflow:auto;width:100%}@media only screen and (max-width: 600px){._4V9X7{width:100%}}.KT7Av{min-height:840px;display:flex;flex-direction:column;justify-content:space-between;background-color:white;width:calc(100% - 230px)}@media only screen and (max-width: 600px){.KT7Av{width:100%}}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"marketplaceRouter": "_0a-Vz",
	"marketplaceRouterEditor": "_4V9X7",
	"marketplaceRouterSa": "KT7Av"
};
export default ___CSS_LOADER_EXPORT___;
