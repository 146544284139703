// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/@wix/yoshi-style-dependencies/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".E2r36{color:#60bc57;margin-right:4px}.PZTiO{display:flex}.NLJhS{display:flex}.NLJhS :nth-child(1){margin-right:4px;flex-shrink:0}.NLJhS :nth-child(2){flex-shrink:1}.ZZvw-{display:flex;color:#32536a;align-items:flex-start}.ZZvw- svg{margin-right:4px;flex-shrink:0}.ZZvw- :nth-child(2){flex-shrink:1;word-break:break-word}.VcXEk{margin-inline-start:-3px}.OAzlQ{margin-inline-start:-2px}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"installed-icon": "E2r36",
	"installedIcon": "E2r36",
	"flex-view": "PZTiO",
	"flexView": "PZTiO",
	"bundle-badge": "NLJhS",
	"bundleBadge": "NLJhS",
	"premium-badge": "ZZvw-",
	"premiumBadge": "ZZvw-",
	"premiumSmallIcon": "VcXEk",
	"warning-icon": "OAzlQ",
	"warningIcon": "OAzlQ"
};
export default ___CSS_LOADER_EXPORT___;
