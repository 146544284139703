// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/@wix/yoshi-style-dependencies/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ZIOkz{display:block;padding:18px 0 24px 0}.ZIOkz ._7WrhX{font-size:16px !important;line-height:24px !important;display:block;margin-bottom:6px}.ZIOkz .xYBof{margin-bottom:24px}.bP02C{display:inline-grid;margin-right:8px;margin-bottom:8px}.EHgZ5{width:100%;display:block;margin-bottom:4px}.Dunh9{display:inline-grid;margin-bottom:8px}.wOOp4{display:inline-block;height:24px;margin-right:18px;margin-left:18px;margin-bottom:8px;vertical-align:top}.TOczL{display:block;width:85%;margin-top:24px}.TOczL .z6S0C{display:inline-block;height:30px;margin-right:17px;margin-left:6px;vertical-align:top}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"categoryHeader": "ZIOkz",
	"categoryHeaderName": "_7WrhX",
	"categoryHeaderDescription": "xYBof",
	"selectorButton": "bP02C",
	"selectorsChecked": "EHgZ5",
	"selectorButtonAll": "Dunh9",
	"selectorsDividerAll": "wOOp4",
	"subCategoriesSelector": "TOczL",
	"selectorsDivider": "z6S0C"
};
export default ___CSS_LOADER_EXPORT___;
