// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/@wix/yoshi-style-dependencies/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CahvI{height:133px}.XKzb0{height:24px}.UdQe3{display:flex;flex-direction:column}.Z3D0J{max-height:36px;max-width:308px}.uxcQ1{font-family:HelveticaNeueW01-75Bold, HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi,\"Helvetica Neue\",\"Helvetica\",\"Arial\",\"\\30E1\\30A4\\30EA\\30AA\",\"meiryo\",\"\\30D2\\30E9\\30AE\\30CE\\89D2\\30B4   pro w3\",\"hiragino kaku gothic pro\",\"sans-serif\";font-size:18px !important}.F\\+IJA{font-size:18px !important}._2TSLv{justify-content:space-between;border-radius:8px}.-B\\+FJ{margin-top:15px;margin-bottom:24px}.KjGB0{width:112px}.VW4V7{border-radius:6px;height:54px;width:54px;background-color:rgba(22,45,61,0.04);-o-object-fit:contain;object-fit:contain}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"height-solution-detail": "CahvI",
	"heightSolutionDetail": "CahvI",
	"solution-name": "XKzb0",
	"solutionName": "XKzb0",
	"title-company": "UdQe3",
	"titleCompany": "UdQe3",
	"solution-teaser": "Z3D0J",
	"solutionTeaser": "Z3D0J",
	"solution-title": "uxcQ1",
	"solutionTitle": "uxcQ1",
	"madefor-solution-title": "F+IJA",
	"madeforSolutionTitle": "F+IJA",
	"adp-banner": "_2TSLv",
	"adpBanner": "_2TSLv",
	"adp-banner-wrapper": "-B+FJ",
	"adpBannerWrapper": "-B+FJ",
	"adp-banner-cta": "KjGB0",
	"adpBannerCta": "KjGB0",
	"adp-banner-logo": "VW4V7",
	"adpBannerLogo": "VW4V7"
};
export default ___CSS_LOADER_EXPORT___;
