// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/@wix/yoshi-style-dependencies/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._76ZsQ{font-size:24px !important;line-height:30px !important}.zsPqx{color:#32536a !important;display:block;font-family:var(--wsr-font-family, HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi),Helvetica Neue,Helvetica,Arial,\"メイリオ\",meiryo,\"ヒラギノ角ゴpro w3\",hiragino kaku gothic pro,sans-serif !important;font-weight:500;font-size:12px !important;text-transform:uppercase;line-height:24px !important;padding-bottom:6px}.WsqOH{display:block}@media only screen and (max-width: 600px){.WsqOH{font-family:var(--wsr-font-family, HelveticaNeueW01-75Medi, HelveticaNeueW02-75Medi, HelveticaNeueW10-75Medi),Helvetica Neue,Helvetica,Arial,\"メイリオ\",meiryo,\"ヒラギノ角ゴpro w3\",hiragino kaku gothic pro,sans-serif !important;font-size:18px;font-weight:bold;font-stretch:normal;font-style:normal;line-height:1.33;letter-spacing:normal;color:#162d3d}}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"collectionTitle": "_76ZsQ",
	"collectionName": "zsPqx",
	"collectionTeaser": "WsqOH"
};
export default ___CSS_LOADER_EXPORT___;
