// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/@wix/yoshi-style-dependencies/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2o4oZ{text-decoration:none}.E\\+7bz{color:initial;text-decoration:none}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"link": "_2o4oZ",
	"box-link": "E+7bz",
	"boxLink": "E+7bz"
};
export default ___CSS_LOADER_EXPORT___;
