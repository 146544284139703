// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/@wix/yoshi-style-dependencies/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@media only screen and (max-width: 600px){.zzoag{display:none !important}}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sidebar": "zzoag"
};
export default ___CSS_LOADER_EXPORT___;
