// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/@wix/yoshi-style-dependencies/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EtiJb{width:100%;height:40px;line-height:35px}.EtiJb:hover{background-color:#eaf7ff;color:#49a2ee}.EtiJb.h1VJi{background-color:#daeffe;color:#49a2ee}.EtiJb .LOJh\\+{margin-right:10px}.EtiJb .RhyGR{display:flex}.f6-M2{color:#162d3d !important}.cEgPo{color:#c73b99 !important}.cEgPo:hover{color:#e869be !important}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menuItem": "EtiJb",
	"selected": "h1VJi",
	"menu-item-icon": "LOJh+",
	"menuItemIcon": "LOJh+",
	"sidebar-section-item": "RhyGR",
	"sidebarSectionItem": "RhyGR",
	"menu-item-link": "f6-M2",
	"menuItemLink": "f6-M2",
	"menu-item-link-holiday": "cEgPo",
	"menuItemLinkHoliday": "cEgPo"
};
export default ___CSS_LOADER_EXPORT___;
