// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/@wix/yoshi-style-dependencies/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._7V7u-{display:flex;justify-content:space-between;margin-bottom:6px;align-items:flex-start}.xPiBO{width:42px;height:42px;border-radius:6px;background:rgba(22,45,61,0.04);border:1px solid #f0f4f7;margin-right:12px;box-sizing:border-box}@media only screen and (max-width: 600px){.xPiBO{width:36px;height:36px}}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"top-section": "_7V7u-",
	"topSection": "_7V7u-",
	"app-icon": "xPiBO",
	"appIcon": "xPiBO"
};
export default ___CSS_LOADER_EXPORT___;
