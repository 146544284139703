// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/@wix/yoshi-style-dependencies/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._5qGis{width:100%;max-width:1254px;margin:33px auto 0}@media only screen and (max-width: 600px){._5qGis{width:100%}}.NZyJm{max-width:570px;margin-top:18px}.tK95i{font-size:32px !important;line-height:42px !important}._6Ce8s{display:flex;position:relative;justify-content:space-between;margin-top:24px;margin-bottom:-33px}@media only screen and (max-width: 600px){._6Ce8s{margin-bottom:50px}}.Fqg3z{padding-bottom:12px;z-index:2}.m4rMy{margin:0px 6px 6px 0px}.V\\+8h7{margin:0px 0px 6px 6px}@media only screen and (max-width: 1195px){.WT4nX{max-width:719px}}.iqx4D{background-color:transparent !important}.iqx4D>*{background-color:transparent !important}.ut9QU{margin-top:18px;display:flex}.G2Kuc{margin-top:18px;display:flex;flex-direction:column}.\\+qIbo{margin-top:-18px;width:240px;height:180px}._8LZvJ{display:flex}@media only screen and (max-width: 600px){.zzk2l{display:none}}._8gLHt{margin-top:18px;margin-bottom:-18px}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section": "_5qGis",
	"homeTitle": "NZyJm",
	"home-heading": "tK95i",
	"homeHeading": "tK95i",
	"homeHead": "_6Ce8s",
	"mediumCategoriesTagsTitle": "Fqg3z",
	"categoriesTagMedium": "m4rMy",
	"categoriesTagSmall": "V+8h7",
	"categoriesTags": "WT4nX",
	"overrideBackground": "iqx4D",
	"categoriesTagsWrapperSmall": "ut9QU",
	"categoriesTagsWrapperMedium": "G2Kuc",
	"homeWithCategoriesTagsImage": "+qIbo",
	"homeTitleWrapper": "_8LZvJ",
	"homeWithSearchImage": "zzk2l",
	"backToReferrer": "_8gLHt"
};
export default ___CSS_LOADER_EXPORT___;
