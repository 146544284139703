// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/@wix/yoshi-style-dependencies/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".l-9NO{font-size:36px !important;font-weight:400 !important;line-height:48px !important;font-family:HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, \"Helvetica Neue\", Helvetica, Arial, メイリオ, meiryo, \"ヒラギノ角ゴ pro w3\", \"hiragino kaku gothic pro\", sans-serif !important;text-align:center}.lsz1q{font-size:18px !important;font-weight:var(--wsr-font-weight-regular, 400) !important;line-height:24px !important;text-align:center}.t1A8x{font-size:32px !important;line-height:42px !important}@media only screen and (max-width: 600px){.t1A8x{margin:16px 12px 0px 12px !important;font-size:20px !important;line-height:24px !important}}.t1A8x:nth-child(2){margin-bottom:12px}.cHfit{inline-size:-moz-max-content !important;inline-size:max-content !important}@media only screen and (max-width: 600px){.cHfit{margin-bottom:18px !important}}._6JwD3{text-align:center}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"banner-text": "l-9NO",
	"bannerText": "l-9NO",
	"content": "lsz1q",
	"title-banner": "t1A8x",
	"titleBanner": "t1A8x",
	"button-wrapper": "cHfit",
	"buttonWrapper": "cHfit",
	"center-wrapper": "_6JwD3",
	"centerWrapper": "_6JwD3"
};
export default ___CSS_LOADER_EXPORT___;
