// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/@wix/yoshi-style-dependencies/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CekDR{height:100%}.CekDR [data-hook='marketplace']{height:100%;overflow:hidden}.a5nBM{background-color:#222;padding:10px;color:white;font-size:20pt}.fMHvp{font-size:15pt}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "CekDR",
	"header": "a5nBM",
	"intro": "fMHvp"
};
export default ___CSS_LOADER_EXPORT___;
