// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/@wix/yoshi-style-dependencies/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".JNfBE{width:546px;background-color:#ffffff}@media only screen and (max-width: 600px){.JNfBE{max-width:85vw}}@media only screen and (max-width: 400px){.JNfBE{max-width:80vw}}.JNfBE input{-webkit-appearance:none;-moz-appearance:none}.JNfBE._96GKe{width:194px;border-radius:100px}.JNfBE.FUgta{width:180px}.JNfBE.GFgjH{width:294px;background-color:#ECEFF3 !important}.JNfBE.C82MP{width:419px;background-color:#ECEFF3 !important}.JNfBE.-k-E9{border:none;box-shadow:none;width:360px}.w2Q5E{display:flex;flex-direction:row;padding-top:5px}.\\+Uu6T{padding-right:2px}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"search-input": "JNfBE",
	"searchInput": "JNfBE",
	"is-in-menu": "_96GKe",
	"isInMenu": "_96GKe",
	"is-in-header": "FUgta",
	"isInHeader": "FUgta",
	"is-in-search-page": "GFgjH",
	"isInSearchPage": "GFgjH",
	"is-in-search-page-wide": "C82MP",
	"isInSearchPageWide": "C82MP",
	"is-expandable": "-k-E9",
	"isExpandable": "-k-E9",
	"icons-wrapper": "w2Q5E",
	"iconsWrapper": "w2Q5E",
	"loader-wrapper": "+Uu6T",
	"loaderWrapper": "+Uu6T"
};
export default ___CSS_LOADER_EXPORT___;
