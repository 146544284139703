// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/@wix/yoshi-style-dependencies/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@media only screen and (max-width: 600px){.Sgz9R{display:none !important}}.Sgz9R .gW\\+wN{display:flex}.Sgz9R .gW\\+wN button:hover{color:#3899ec}.Sgz9R .ZQxGt{width:262px}.Sgz9R .ZQxGt .z77Bh{padding:18px 18px 24px}.Sgz9R .ZQxGt .z77Bh .\\+xaUV{display:flex;align-items:center;justify-content:start}.Sgz9R .ZQxGt .z77Bh .\\+xaUV .g3Bc6{display:flex;flex-direction:column;width:162px;align-items:flex-start}.Sgz9R .ZQxGt .z77Bh .ytZus{margin:18px 0}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popover": "Sgz9R",
	"popover-element": "gW+wN",
	"popoverElement": "gW+wN",
	"popover-content-container": "ZQxGt",
	"popoverContentContainer": "ZQxGt",
	"popover-content": "z77Bh",
	"popoverContent": "z77Bh",
	"user-details": "+xaUV",
	"userDetails": "+xaUV",
	"user-details-text": "g3Bc6",
	"userDetailsText": "g3Bc6",
	"divider": "ytZus"
};
export default ___CSS_LOADER_EXPORT___;
