// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/@wix/yoshi-style-dependencies/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EXWnF{display:none;height:100vh;position:fixed;top:0;left:0;width:90vw;z-index:10;background:white;box-shadow:0px 5px 5px 5px rgba(128,128,128,0.596);transform:translateX(-100vw);transition:transform 400ms}.EXWnF.yHCvU{transform:translateX(0) scale(1)}@media only screen and (max-width: 600px){.EXWnF{display:block}}.Cdfx1{padding:24px 18px}.Cdfx1 .radGp{margin-bottom:14px;display:flex;justify-content:space-between}.Cdfx1 .Pao2k{height:30px}.MAtYU{text-transform:uppercase;display:flex;justify-content:space-between;align-items:center;padding-left:6px}.OQXLM{padding:28px 0 28px 0;box-sizing:border-box;color:#162d3d !important}.OQXLM ._8uQPR{transition:transform 150ms linear}.OQXLM .Hfvsz{padding:18px;padding-top:27px;list-style:none;margin:0}.OQXLM .Hfvsz li a{text-decoration:none}.OQXLM .Hfvsz li:not(:last-of-type){margin-bottom:18px}.OQXLM._04aze ._8uQPR{transform:rotate(90deg)}.nq-s1{margin-top:30px}.jTd0C{color:#c73b99 !important}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mobile-menu": "EXWnF",
	"mobileMenu": "EXWnF",
	"open": "yHCvU",
	"inner-container": "Cdfx1",
	"innerContainer": "Cdfx1",
	"top-section": "radGp",
	"topSection": "radGp",
	"logo": "Pao2k",
	"mobile-menu-section-top": "MAtYU",
	"mobileMenuSectionTop": "MAtYU",
	"section-container": "OQXLM",
	"sectionContainer": "OQXLM",
	"arrow": "_8uQPR",
	"links": "Hfvsz",
	"open-section": "_04aze",
	"openSection": "_04aze",
	"user-details-container": "nq-s1",
	"userDetailsContainer": "nq-s1",
	"title-mobile": "jTd0C",
	"titleMobile": "jTd0C"
};
export default ___CSS_LOADER_EXPORT___;
