// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/@wix/yoshi-style-dependencies/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LF4U\\+{margin-bottom:12px}.cZtuB{margin-bottom:18px}.TOhCN{font-size:24px !important}.q5-L7{width:402px;min-height:90px;padding:24px 24px 18px;box-sizing:border-box;overflow:hidden}.q5-L7 .vxbD5{display:block;margin-bottom:12px}.q5-L7 .OBAGN{overflow-y:scroll;max-height:150px;display:flex;flex-wrap:wrap;padding-top:6px;justify-content:space-between}.q5-L7 .OBAGN .rLZo3{flex-grow:1;width:50%;margin-bottom:12px;max-width:150px}.q5-L7 .poV2C{display:table;margin:18px auto 0px}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"country-list-subtitle": "LF4U+",
	"countryListSubtitle": "LF4U+",
	"country-list": "cZtuB",
	"countryList": "cZtuB",
	"other-countries-title": "TOhCN",
	"otherCountriesTitle": "TOhCN",
	"country-popover": "q5-L7",
	"countryPopover": "q5-L7",
	"country-popover-title": "vxbD5",
	"countryPopoverTitle": "vxbD5",
	"countries-list": "OBAGN",
	"countriesList": "OBAGN",
	"country-popover-country": "rLZo3",
	"countryPopoverCountry": "rLZo3",
	"popover-footer": "poV2C",
	"popoverFooter": "poV2C"
};
export default ___CSS_LOADER_EXPORT___;
