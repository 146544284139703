// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/@wix/yoshi-style-dependencies/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2GtHc{height:42px;width:42px;border-radius:6px;box-sizing:border-box;border:1px solid #f0f4f7;background:linear-gradient(0deg, rgba(22,45,61,0.04), rgba(22,45,61,0.04)),#fff}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": "_2GtHc"
};
export default ___CSS_LOADER_EXPORT___;
