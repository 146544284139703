// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/@wix/yoshi-style-dependencies/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".pBFEo{margin-bottom:30px}._4P6v4{display:flex;margin-bottom:45px}@media only screen and (max-width: 600px){._4P6v4{margin-bottom:27px}}._3MJZF{margin-left:18px}.gWgRe{min-width:24px;min-height:24px;margin-right:2px;fill:#fdb10c}.RAHWT{display:flex;align-items:flex-start;margin-left:-3px}._0yM8t{box-sizing:border-box;border:solid 1px #dfe5eb}@media only screen and (max-width: 600px){.k9GBk{font-family:HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, \"Helvetica Neue\", Helvetica, Arial, \"\\30E1\\30A4\\30EA\\30AA\", meiryo, \"\\30D2\\30E9\\30AE\\30CE\\89D2\\30B4 pro w3\", \"hiragino kaku gothic pro\", sans-serif !important;font-size:14px}}.YrXum{font-weight:500}@media only screen and (max-width: 600px){.YrXum{font-family:var(--wsr-font-family, HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi),\"Helvetica Neue\",\"Helvetica\",\"Arial\",\"メイリオ\",\"meiryo\",\"ヒラギノ角ゴpro w3\",\"hiragino kaku gothic pro\",\"sans-serif\" !important;font-size:14px}}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"back-btn": "pBFEo",
	"backBtn": "pBFEo",
	"company-header": "_4P6v4",
	"companyHeader": "_4P6v4",
	"info-container": "_3MJZF",
	"infoContainer": "_3MJZF",
	"star": "gWgRe",
	"rating": "RAHWT",
	"logo": "_0yM8t",
	"rating-text": "k9GBk",
	"ratingText": "k9GBk",
	"rating-number": "YrXum",
	"ratingNumber": "YrXum"
};
export default ___CSS_LOADER_EXPORT___;
