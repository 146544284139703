// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/@wix/yoshi-style-dependencies/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".vuw51{margin:0;padding-top:24px;box-sizing:border-box}@media only screen and (max-width: 600px){.vuw51{padding-top:18px}}._8yhPk{width:100%;background-color:#f0f4f7;margin-top:39px}.-ey4y{max-width:1266px;margin:0 auto;padding-left:48px;padding-right:48px}@media only screen and (max-width: 600px){.-ey4y{padding-left:4vw;padding-right:4vw}}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"conatiner": "vuw51",
	"carousel-container": "_8yhPk",
	"carouselContainer": "_8yhPk",
	"carousel": "-ey4y"
};
export default ___CSS_LOADER_EXPORT___;
