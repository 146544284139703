// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/@wix/yoshi-style-dependencies/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".R0npi{min-width:270px;padding:18px;transition:transform 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);box-sizing:border-box;border:solid 1px #dfe5eb;border-radius:8px;cursor:pointer;height:100%}.R0npi:hover{transform:translateY(-6px);box-shadow:0px 2px 12px rgba(0,6,36,0.16)}.R0npi:hover .Grg2x{text-decoration:underline}.Zs0kY{height:100%}.wxw1Z{border-radius:6px;box-sizing:border-box;border:1px solid #f0f4f7;background:linear-gradient(0deg, rgba(22,45,61,0.04), rgba(22,45,61,0.04)),#fff}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"app-box": "R0npi",
	"appBox": "R0npi",
	"cta-button": "Grg2x",
	"ctaButton": "Grg2x",
	"app-box-resize-detector": "Zs0kY",
	"appBoxResizeDetector": "Zs0kY",
	"icon": "wxw1Z"
};
export default ___CSS_LOADER_EXPORT___;
