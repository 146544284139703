// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/@wix/yoshi-style-dependencies/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".bD6OQ{background-color:#f0f3f5;height:100%}.bD6OQ .DvdMB{padding-top:42px;padding-bottom:30px;text-align:center}.bD6OQ .Kr7XE{display:flex;align-items:center;border-radius:8px;margin:0 36px;padding:30px 24px;background-color:#ffffff}.bD6OQ .Kr7XE img{width:60px;height:60px;border-radius:50%}.bD6OQ .Kr7XE ._3JZkO{display:flex;flex-direction:column;justify-content:center;margin-left:18px}.bD6OQ .tQ8CZ{margin-left:auto}.bD6OQ .ahlR4{display:inline;margin-left:18px;position:relative;top:24px}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"finishSetup": "bD6OQ",
	"header": "DvdMB",
	"appCard": "Kr7XE",
	"column": "_3JZkO",
	"addToSite": "tQ8CZ",
	"manageAppsLink": "ahlR4"
};
export default ___CSS_LOADER_EXPORT___;
