// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/@wix/yoshi-style-dependencies/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OIh5T{margin-right:6px;margin-left:6px}._9r1he{padding-top:12px;padding-bottom:9px;display:flex}._0dLLb{cursor:pointer}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textButton": "OIh5T",
	"changeLocationModalWrapper": "_9r1he",
	"infoIconWrapper": "_0dLLb"
};
export default ___CSS_LOADER_EXPORT___;
