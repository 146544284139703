// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/@wix/yoshi-style-dependencies/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../../node_modules/@wix/yoshi-style-dependencies/node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../assets/gif/progBar_x1.gif";
import ___CSS_LOADER_URL_IMPORT_1___ from "../../assets/images/empty-state-generic-nothing-here-desert.svg";
import ___CSS_LOADER_URL_IMPORT_2___ from "../../assets/gif/progBar_x2.gif";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UF0MX .EfEOe{display:block;width:84px;height:84px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:100%}.UF0MX .eVaHl{text-align:center}.UF0MX ._1V\\+qw{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){.UF0MX .EfEOe{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ")}}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"open-app": "UF0MX",
	"openApp": "UF0MX",
	"loader": "EfEOe",
	"text": "eVaHl",
	"empty-pic": "_1V+qw",
	"emptyPic": "_1V+qw"
};
export default ___CSS_LOADER_EXPORT___;
