// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/@wix/yoshi-style-dependencies/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._9AIb-{height:100%;border:solid 1px #dfe5eb;border-radius:8px;cursor:pointer;transition:transform 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94)}._9AIb-:hover{transform:translateY(-6px);box-shadow:0 2px 12px 0 rgba(0,6,36,0.16)}.jEVhV{height:100%}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"promo-box": "_9AIb-",
	"promoBox": "_9AIb-",
	"promo-box-resize-detector-wrapper": "jEVhV",
	"promoBoxResizeDetectorWrapper": "jEVhV"
};
export default ___CSS_LOADER_EXPORT___;
