// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/@wix/yoshi-style-dependencies/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Oy4Ps{margin-top:50px;padding:42px 48px;border-radius:8px;border:solid 1px #dfe5eb;background-color:#ffffff}.Oy4Ps ._5-pIR{margin-top:6px}.Oy4Ps .U0Dhb{margin-top:30px;width:-moz-fit-content;width:fit-content}.Oy4Ps ._9pWxl{height:120px;margin-right:22px;border-radius:8px}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"terminator": "Oy4Ps",
	"description": "_5-pIR",
	"cta": "U0Dhb",
	"icon": "_9pWxl"
};
export default ___CSS_LOADER_EXPORT___;
