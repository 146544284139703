// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/@wix/yoshi-style-dependencies/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Y5AIg{padding-left:4px}.\\+sr2q{color:#162d3d !important}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"breadcrumbsContainer": "Y5AIg",
	"breadcrumb-link": "+sr2q",
	"breadcrumbLink": "+sr2q"
};
export default ___CSS_LOADER_EXPORT___;
