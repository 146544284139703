// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/@wix/yoshi-style-dependencies/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".k6PC1{display:flex;align-items:center;white-space:unset !important}.Wg7EJ{color:#60bc57;margin-right:4px;margin-inline-start:-2px;margin-top:2px}.X7xVf{display:flex}.X7xVf :nth-child(1){margin-right:4px;flex-shrink:0}.X7xVf :nth-child(2){flex-shrink:1}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"centered-view": "k6PC1",
	"centeredView": "k6PC1",
	"installed-icon": "Wg7EJ",
	"installedIcon": "Wg7EJ",
	"bundle-badge": "X7xVf",
	"bundleBadge": "X7xVf"
};
export default ___CSS_LOADER_EXPORT___;
