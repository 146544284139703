// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/@wix/yoshi-style-dependencies/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".V1hJI{width:100%;max-width:1254px;margin:33px auto 0}@media only screen and (max-width: 600px){.V1hJI{width:100%}}.tGr9y{background-color:#f0f4f7;padding:24px 48px 24px;display:flex;justify-content:center;width:calc(100% - 96px);margin:33px 0 48px 0;box-sizing:content-box}@media only screen and (max-width: 600px){.tGr9y{display:block;padding:36px 18px 30px;margin:48px -4vw;width:100%}}.NdAOk{display:flex;flex-direction:column;width:100%}.NdAOk .lKsIA{font-size:24px !important}.fpV4P{display:block}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section": "V1hJI",
	"wrapper": "tGr9y",
	"content-wrapper": "NdAOk",
	"contentWrapper": "NdAOk",
	"widget-title": "lKsIA",
	"widgetTitle": "lKsIA",
	"categoriesCarouselWrapper": "fpV4P"
};
export default ___CSS_LOADER_EXPORT___;
