// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/@wix/yoshi-style-dependencies/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".lXhU3{width:100%;height:36px;border:solid 1px #dfe5eb;background-color:#f0f4f7;box-sizing:border-box}@media only screen and (max-width: 600px){.lXhU3{width:calc(100vw + 25px);margin-left:-25px}}.K9Obr{padding-left:24px;margin-top:9px}@media only screen and (max-width: 600px){.K9Obr{padding-left:40px}}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"apps-suggestions-title": "lXhU3",
	"appsSuggestionsTitle": "lXhU3",
	"title-text": "K9Obr",
	"titleText": "K9Obr"
};
export default ___CSS_LOADER_EXPORT___;
