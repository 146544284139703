// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/@wix/yoshi-style-dependencies/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".eMZVd{margin-bottom:39px}.eMZVd .yr8iV{margin-top:-256px;padding-top:50px}.eMZVd .WlQF8{margin:0}@media only screen and (max-width: 600px){.eMZVd .Nxr5d{margin-top:18px !important}}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"newHeaderWrapper": "eMZVd",
	"overrideBackground": "yr8iV",
	"apps-count": "WlQF8",
	"appsCount": "WlQF8",
	"mobileHeaderSpacing": "Nxr5d"
};
export default ___CSS_LOADER_EXPORT___;
