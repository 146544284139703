// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/@wix/yoshi-style-dependencies/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._8PU8S{cursor:pointer;transition:transform 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);width:370px;height:162px;margin:6px 12px 0 0}._8PU8S:hover{transform:translateY(-6px)}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"category-card": "_8PU8S",
	"categoryCard": "_8PU8S"
};
export default ___CSS_LOADER_EXPORT___;
