// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/@wix/yoshi-style-dependencies/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".q8iwo{width:18px;padding:2px 3px 2px 0;margin-inline-start:-3px}.BRXJM{margin-right:5px}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"star-icon": "q8iwo",
	"starIcon": "q8iwo",
	"rating": "BRXJM"
};
export default ___CSS_LOADER_EXPORT___;
