// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/@wix/yoshi-style-dependencies/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LuhVT{width:96px;height:96px}._8VTkP [data-hook=\"solution-name\"]{font-weight:bold}@media only screen and (max-width: 768px){._8VTkP [data-hook=\"solution-name\"]{text-align:center}}.FKhTU{margin-top:24px;margin-bottom:0;margin-right:24px;border-radius:8px}.MqQ4Y{max-width:184px;margin-top:6px;height:54px}@media only screen and (max-width: 768px){.MqQ4Y{text-align:center;max-height:54px}}.SB5vI{border-radius:8px;cursor:pointer;width:322px;height:114px;padding:24px 24px;background-color:#ffffff;transition:transform 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94)}.SB5vI:hover{transform:translateY(-6px)}@media only screen and (max-width: 768px){.SB5vI{border-radius:8px;cursor:pointer;width:180px;height:228px;padding:24px 24px}.SB5vI:hover{transform:translateY(-6px)}}._7JnhA{display:flex;justify-content:flex-end}@media only screen and (max-width: 768px){._7JnhA{flex-direction:column-reverse;align-content:center}}._6iNvl{display:flex;flex-direction:row;justify-content:space-between;width:100%}@media only screen and (max-width: 768px){._6iNvl{flex-direction:column-reverse;align-items:center;justify-content:flex-end}}._5hJ05{padding-top:6px}@media only screen and (max-width: 768px){._5hJ05{padding-top:12px}}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"image": "LuhVT",
	"title-wrapper": "_8VTkP",
	"titleWrapper": "_8VTkP",
	"slide": "FKhTU",
	"teaser": "MqQ4Y",
	"box": "SB5vI",
	"imgWrapper": "_7JnhA",
	"boxContent": "_6iNvl",
	"solutionName": "_5hJ05"
};
export default ___CSS_LOADER_EXPORT___;
