// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/@wix/yoshi-style-dependencies/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".S8bYE,.FC0D9{min-height:760px;display:flex;justify-content:center;align-items:center;margin-bottom:760px}.FC0D9{background-color:#ECEFF3 !important;height:100%;margin-bottom:0px}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loader-position": "S8bYE",
	"loaderPosition": "S8bYE",
	"loader-position-grey": "FC0D9",
	"loaderPositionGrey": "FC0D9"
};
export default ___CSS_LOADER_EXPORT___;
