// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/@wix/yoshi-style-dependencies/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../../node_modules/@wix/yoshi-style-dependencies/node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../assets/fonts/WixMadeForDisplay/WixMadeforDisplay_W_Bd.woff2";
import ___CSS_LOADER_URL_IMPORT_1___ from "../../assets/fonts/WixMadeForDisplay/WixMadeforDisplay_W_Bd.woff";
import ___CSS_LOADER_URL_IMPORT_2___ from "../../assets/fonts/WixMadeForText/WixMadeforText_W_Bd.woff2";
import ___CSS_LOADER_URL_IMPORT_3___ from "../../assets/fonts/WixMadeForText/WixMadeforText_W_Bd.woff";
import ___CSS_LOADER_URL_IMPORT_4___ from "../../assets/fonts/WixMadeForText/WixMadeforText_W_Rg.woff2";
import ___CSS_LOADER_URL_IMPORT_5___ from "../../assets/fonts/WixMadeForText/WixMadeforText_W_Rg.woff";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:\"WixMadeforDisplay\";font-weight:bold;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"woff\")}@font-face{font-family:\"WixMadeforText\";font-weight:bold;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format(\"woff\")}@font-face{font-family:\"WixMadeforText\";font-weight:normal;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ") format(\"woff\")}.o62K2{width:60px;height:60px;border-radius:6px;padding-bottom:24px}.VcGMo{width:332px;font-family:WixMadeforDisplay;font-weight:bold;font-size:18px;line-height:1.33;letter-spacing:normal;text-align:center;padding-bottom:16px;color:#000624}._1LbDu{width:332px;font-family:WixMadeforText;font-size:14px;font-weight:normal;font-stretch:normal;font-style:normal;line-height:1.43;letter-spacing:normal;text-align:center;padding-bottom:28px;color:#595d70}._2rEi4{width:70px;height:36px;font-family:WixMadeforText;font-size:14px;font-weight:bold;font-stretch:normal;font-style:normal;line-height:1.43;letter-spacing:normal;color:white;background-color:#116DFF;border-radius:4px;cursor:pointer}._2rEi4:hover{background-color:#0F62E6}.JQ3xX{padding-bottom:24px}.nYrXl{display:flex;height:36px;align-self:flex-end;padding-right:16px;padding-top:16px}.DKP5Q{cursor:pointer}.EfJ1j{display:flex;flex-direction:column;background-color:white;border-radius:8px;width:420px;height:228px;align-items:center}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"app-icon": "o62K2",
	"appIcon": "o62K2",
	"editor-x-modal-title": "VcGMo",
	"editorXModalTitle": "VcGMo",
	"editor-x-modal-subtitle": "_1LbDu",
	"editorXModalSubtitle": "_1LbDu",
	"editor-x-modal-cta": "_2rEi4",
	"editorXModalCta": "_2rEi4",
	"editor-x-modal-cta-wrapper": "JQ3xX",
	"editorXModalCtaWrapper": "JQ3xX",
	"editor-x-modal-close-wrapper": "nYrXl",
	"editorXModalCloseWrapper": "nYrXl",
	"editor-x-modal-close": "DKP5Q",
	"editorXModalClose": "DKP5Q",
	"editor-x-modal-box": "EfJ1j",
	"editorXModalBox": "EfJ1j"
};
export default ___CSS_LOADER_EXPORT___;
