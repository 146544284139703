// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/@wix/yoshi-style-dependencies/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1rFyA{border:solid 1px #dfe5eb;border-radius:8px;cursor:pointer;top:0;position:relative;transition:transform 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94)}._1rFyA:hover{transform:translateY(-6px)}._1rFyA [data-hook=\"hover-component\"]{overflow:initial}._1rFyA [data-hook=\"hover-component\"] div:not(.V8vjZ){display:none}._1rFyA:hover{transform:translateY(-6px);box-shadow:0 2px 12px 0 rgba(0,6,36,0.16)}@media only screen and (max-width: 600px){._1rFyA:hover{transform:none;box-shadow:none}}._1rFyA .RDsuK{width:100%;height:100%}._1rFyA .RDsuK .OYwuu{display:grid;position:relative}._1rFyA .RDsuK .OYwuu ._2cUMd{grid-row:1;height:100%}._1rFyA .RDsuK .OYwuu ._2cUMd .V8vjZ{background-size:cover;background-repeat:no-repeat;width:100%;height:100%;position:relative;border-top-left-radius:8px;border-top-right-radius:8px}._1rFyA .RDsuK .\\+fHxA{grid-column:1;z-index:1}._1rFyA .RDsuK .\\+fHxA .OmyJO .XQ8A5{position:absolute;top:2px;left:2px;width:36px;height:36px;border-radius:6px;border:solid 1px #DFE5EB;background:#ffffff}._1rFyA .RDsuK .R\\+5a1{display:flex;flex-direction:column;justify-content:space-between}._1rFyA .RDsuK .R\\+5a1 .V9Cdn{max-width:260px}._1rFyA .RDsuK .syPop{padding-top:6px;display:flex;justify-content:space-between;align-items:flex-end}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"promo-box": "_1rFyA",
	"promoBox": "_1rFyA",
	"poster": "V8vjZ",
	"promo-box-card": "RDsuK",
	"promoBoxCard": "RDsuK",
	"images-stack": "OYwuu",
	"imagesStack": "OYwuu",
	"bottom-image": "_2cUMd",
	"bottomImage": "_2cUMd",
	"top-image": "+fHxA",
	"topImage": "+fHxA",
	"icon-outline": "OmyJO",
	"iconOutline": "OmyJO",
	"icon": "XQ8A5",
	"solution-details": "R+5a1",
	"solutionDetails": "R+5a1",
	"solution-description": "V9Cdn",
	"solutionDescription": "V9Cdn",
	"action-bar": "syPop",
	"actionBar": "syPop"
};
export default ___CSS_LOADER_EXPORT___;
