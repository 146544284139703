// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/@wix/yoshi-style-dependencies/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1u0pU{display:flex;justify-content:space-between;margin-bottom:12px;align-items:flex-start}.nSj48{width:54px;height:54px;border-radius:6px;box-sizing:border-box;background:rgba(22,45,61,0.04);border:1px solid #f0f4f7}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"top-section": "_1u0pU",
	"topSection": "_1u0pU",
	"app-icon": "nSj48",
	"appIcon": "nSj48"
};
export default ___CSS_LOADER_EXPORT___;
