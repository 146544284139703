import {
  appHasEditor,
  isBundleAppAndInstalled,
  isBundleAppAndNotInstalled,
  isNewVersionAvailable,
  isSetupIncomplete,
  isPendingConfiguration,
  getAppDashboardDataNew,
  isSlotsApp,
  getBackOfficePageData,
} from '@wix/manage-installed-apps';
import { EditorType, Path } from '@wix/marketplace-component';
import { IAppInstallParams } from '@wix/installation-manager-client-sdk';
import { updatePendingService } from './api';
import { BundleWebSolution } from '@wix/ambassador-marketplace/types';
import {
  AppGroup,
  ComponentType,
  ManagedApp,
} from '@wix/ambassador-devcenter-managedapps-v1-managed-app/types';

export const openPackagePicker = (
  app: ManagedApp,
  getAppData,
  editorType: string,
  msid: string,
  ppOrigin?: string,
) => {
  const appId = (app as ManagedApp)?.appId;
  const appInstance = getAppData(appId)?.instance;
  const appPublishedVersion = (app as ManagedApp)?.appInfo?.publishedVersion;
  const externalPricingPageUrl = (app as ManagedApp)?.pricingDetails
    ?.externalPricingPageUrl;

  if (externalPricingPageUrl) {
    window.open(`${externalPricingPageUrl}?instance=${appInstance}`, '_blank');
  } else {
    const domain =
      editorType === EditorType.EDITORX ? `manage.editorx.com` : `www.wix.com`;
    window.open(
      `https://${domain}/apps/upgrade/${appId}?metaSiteId=${msid}&pp_origin=${ppOrigin}&publishedVersion=${appPublishedVersion}`,
      '_blank',
    );
  }
};

export const openApp = async (
  app: ManagedApp | BundleWebSolution,
  appMarketHandlers: any,
  metaSiteId: string,
  editorType: string,
  experiments: any,
  options?: any,
) => {
  const appId = (app as ManagedApp).appId;
  if(!appId){
    throw new Error('No app id in open app');
  }
  const isSlotAppResponse = await isSlotsApp(appId);
  if ((app as ManagedApp).installationDetails?.group === AppGroup.PENDING) {
    const version = (app as ManagedApp).installationDetails?.installedVersion;
    const versionToEditor = version !== '' ? version : null;
    appMarketHandlers.addApp({
      appDefinitionId: (app as ManagedApp).appId,
      appDefId: (app as ManagedApp).appId,
      appVersion: versionToEditor,
    });
    finishAppInstall(
      (app as ManagedApp).appId,
      experiments,
      appMarketHandlers.getDataByAppDefId,
      editorType,
    );
  } else if (
    appHasEditor(app as ManagedApp) &&
    !isSlotAppResponse &&
    !isPendingConfiguration(app as ManagedApp)
  ) {
    appMarketHandlers.openApp({
      params: {
        appDefinitionId: (app as ManagedApp).appId,
        appDefId: (app as ManagedApp).appId,
      },
      options,
    });
  } else {
    const dashboardData = getAppDashboardDataNew(app as ManagedApp);
    const backOfficePageData = getBackOfficePageData(app as ManagedApp)
    const isEmbedded = dashboardData && dashboardData.embedded;
    const externalDashboardUrl = dashboardData && dashboardData.url;
    const shouldOpenInExternal = !isEmbedded && externalDashboardUrl;
    if (dashboardData||backOfficePageData) {
      if (shouldOpenInExternal) {
        const instance = appMarketHandlers?.getDataByAppDefId(
          (app as ManagedApp).appId,
        ).instance;
        window.open(`${externalDashboardUrl}?instance=${instance}`);
      } else {
        const domain =
          editorType === EditorType.EDITORX
            ? `manage.editorx.com`
            : `www.wix.com`;
        window.open(
          `https://${domain}/dashboard/${metaSiteId}/app/${appId}/?referralInfo=ed_market`,
        );
      }
    }
  }
};

const installApp = (
  app: BundleWebSolution | ManagedApp,
  installAppFunc: (params: IAppInstallParams) => void,
  onInstallationSuccess: IAppInstallParams['onInstallationSuccess'],
  experiments: any,
) => {
  let appInstallParams;
  const appToInstall = app as ManagedApp;
  appInstallParams = {
    appDefId: appToInstall.appId,
    openMethod: appToInstall.appInfo.openConsentIn,
    onInstallationSuccess,
  };
  installAppFunc(appInstallParams);
};

export const manageAppsMainButtonAction = async (
  app: BundleWebSolution | ManagedApp,
  bundleApps: BundleWebSolution[],
  appMarketHandlers: any,
  metaSiteId: string,
  editorType: string,
  goto: any,
  installAppFunc: (params: IAppInstallParams) => void,
  onInstallationSuccessCb: IAppInstallParams['onInstallationSuccess'],
  experiments,
) => {
  const appId = (app as ManagedApp)?.appId || '';
  const slug = (app as ManagedApp)?.appInfo?.slug || '';

  if (isBundleAppAndInstalled(appId, bundleApps)) {
    openPackagePicker(
      app as ManagedApp,
      appMarketHandlers.getDataByAppDefId,
      editorType,
      metaSiteId,
      'manage apps editor',
    );
  } else if (isBundleAppAndNotInstalled(appId, bundleApps)) {
    goto({
      path: Path.WEB_SOLUTION,
      slug,
      referral: 'action_required_section',
    });
  } else if (isNewVersionAvailable(app as ManagedApp)) {
    const publishedVersion =
      (app as ManagedApp)?.appInfo?.publishedVersion || '0.0.0';
    const appIncludesBlocks = (app as ManagedApp).components.some(
      comp => comp.compType === ComponentType.STUDIO,
    );

    const OISCB: IAppInstallParams['onInstallationSuccess'] = params => {
      onInstallationSuccessCb(params);

      if (appIncludesBlocks) {
        appMarketHandlers.updateApp({
          params: {
            appDefinitionId: appId,
            appVersion: publishedVersion,
          },
        });
      }
    };

    installApp(app, installAppFunc, OISCB, experiments);
  } else if (
    !isSetupIncomplete(app as ManagedApp) ||
    isPendingConfiguration(app as ManagedApp)
  ) {
    await openApp(app, appMarketHandlers, metaSiteId, editorType, experiments);
  } else if (isSetupIncomplete(app as ManagedApp)) {
    installApp(app, installAppFunc, onInstallationSuccessCb, experiments);
  }
};

export const finishAppInstall = (
  appDefId: string,
  experiments: any,
  getDataByAppDefId: any,
  editorType: string,
) => {
  updatePendingService(appDefId, getDataByAppDefId, editorType);
};
