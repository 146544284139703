// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/@wix/yoshi-style-dependencies/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".YzX\\+q [data-hook=\"page\"]{background-color:var(--wsr-color-D80, #fff)}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"app-market-page": "YzX+q",
	"appMarketPage": "YzX+q"
};
export default ___CSS_LOADER_EXPORT___;
