// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/@wix/yoshi-style-dependencies/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Z\\+ykP,.uPBAC{display:flex;flex-direction:column;box-sizing:border-box;width:100%;padding:18px;background:white;cursor:pointer;border-radius:8px;border:solid 1px #dfe5eb;top:0;position:relative;transition:transform 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94)}.Z\\+ykP:hover,.uPBAC:hover{transform:translateY(-6px);box-shadow:0 2px 12px 0 rgba(0,6,36,0.16)}@media only screen and (max-width: 600px){.Z\\+ykP:hover,.uPBAC:hover{transform:none;box-shadow:none}}.uPBAC{border:0}.fhJUD{color:initial;text-decoration:none}.Gzm6X,.xy\\+Uw{min-width:270px;max-width:419px;transition:transform 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);height:216px}@media only screen and (max-width: 600px){.Gzm6X,.xy\\+Uw{max-width:100%;height:auto;margin-top:9px;margin-bottom:9px}}.xy\\+Uw{height:204px}@media only screen and (max-width: 600px){.xy\\+Uw{max-width:100%;height:auto}}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"solution-box-wrapper-marketplace-layout": "Z+ykP",
	"solutionBoxWrapperMarketplaceLayout": "Z+ykP",
	"solution-box-wrapper-integrations-layout": "uPBAC",
	"solutionBoxWrapperIntegrationsLayout": "uPBAC",
	"box-link": "fhJUD",
	"boxLink": "fhJUD",
	"solution-box": "Gzm6X",
	"solutionBox": "Gzm6X",
	"solution-box-no-rate": "xy+Uw",
	"solutionBoxNoRate": "xy+Uw"
};
export default ___CSS_LOADER_EXPORT___;
