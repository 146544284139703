import { HttpClient } from '@wix/http-client';
import { EditorType } from '@wix/marketplace-component';

const httpClient = new HttpClient();

export const fetchInstalledApps = async (
  getDataByAppDefId,
  locale,
  editorType,
) => {
  const msData =
    typeof getDataByAppDefId === 'function'
      ? getDataByAppDefId('22bef345-3c5b-4c18-b782-74d4085112ff')
      : undefined;
  const baseUrl =
    editorType === EditorType.EDITORX ? `create.editorx.com` : `editor.wix.com`;

  const signedInstance = msData ? msData.instance : undefined;
  if (signedInstance) {
    return httpClient
      .get(
        `https://${baseUrl}/_api/managed-apps/v1/managed-apps?lang=${locale}`,
        {
          headers: { Authorization: signedInstance },
          withCredentials: true,
        },
      )
      .then(res => res?.data?.managedApps)
      .catch(error => console.log('cannot fetch installed apps', error));
  }
  return Promise.resolve([]);
};

export const updatePendingService = async (
  appId: string,
  getDataByAppDefId: any,
  editorType: string,
) => {
  const msData =
    typeof getDataByAppDefId === 'function'
      ? getDataByAppDefId('22bef345-3c5b-4c18-b782-74d4085112ff')
      : undefined;

  const signedInstance = msData ? msData.instance : undefined;
  const domain =
    editorType === EditorType.EDITORX ? `create.editorx.com` : `editor.wix.com`;

  if (signedInstance && appId) {
    const pendingServiceUrl = `https://${domain}/_api/pending-installations/v1/complete`;

    return httpClient
      .post(
        pendingServiceUrl,
        {
          appId,
          pendingReason: 'EDITOR',
        },
        {
          headers: { Authorization: signedInstance },
          withCredentials: true,
        },
      )
      .then(res => Promise.resolve([]))
      .catch(error => console.log('cannot update PendingService', error));
  }
  return Promise.resolve([]);
};

export const updateDeleteInstallationService = async (
  appId: string,
  getDataByAppDefId: any,
  editorType: string,
) => {
  const msData =
    typeof getDataByAppDefId === 'function'
      ? getDataByAppDefId('22bef345-3c5b-4c18-b782-74d4085112ff')
      : undefined;

  const signedInstance = msData ? msData.instance : undefined;
  const domain =
    editorType === EditorType.EDITORX ? `create.editorx.com` : `editor.wix.com`;

  if (signedInstance && appId) {
    const pendingServiceUrl = `https://${domain}/_api/pending-installations/v1/pending-installation`;

    return httpClient
      .delete(pendingServiceUrl, {
        headers: { Authorization: signedInstance },
        withCredentials: true,
        data: {
          appId,
        },
      })
      .then(res => Promise.resolve([]))
      .catch(error => console.log('cannot update deleteInstallation', error));
  }
  return Promise.resolve([]);
};

export const decodeJwt = async (signedJwt, editorType): Promise<any> => {
  const domain =
    editorType === EditorType.EDITORX ? `create.editorx.com` : `editor.wix.com`;
  return httpClient
    .get(
      `https://${domain}/_serverless/app-market-json-web-token/json-web-token?signedJwt=${signedJwt}`,
    )
    .then(({ data }) => data)
    .catch(error => {});
};

export const isWixStudioSite = async (getDataByAppDefId, isEditorX) => {
  const msData =
    typeof getDataByAppDefId === 'function'
      ? getDataByAppDefId('22bef345-3c5b-4c18-b782-74d4085112ff')
      : undefined;

  const signedInstance = msData ? msData.instance : undefined;

  const domain = isEditorX ? `create.editorx.com` : `editor.wix.com`;

  if (signedInstance) {
    const metaSiteServiceurl = `https://${domain}/_api/msm/v1/meta-site/get`;

    return httpClient
      .get(metaSiteServiceurl, {
        headers: { Authorization: signedInstance },
        withCredentials: true,
      })
      .then(res => res.data.metaSite?.htmlApp?.studio)
      .catch(error => console.log('cannot check if it is wix studio', error));
  }
  return Promise.resolve([]);
};
