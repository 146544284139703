// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/@wix/yoshi-style-dependencies/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._9O1At{background-color:transparent !important;margin-top:-326px;padding-top:50px}._9O1At>*{background-color:transparent !important}@media only screen and (max-width: 600px){.GFg6l{padding:18px 0}}.DMvOM{height:264px}.PtGX7{padding:0 48px}@media only screen and (max-width: 600px){.PtGX7{padding:0 18px}}._1pKhm{padding-top:24px;padding-bottom:9px}.IW0Oh{margin:0}._0DSqY{margin-right:6px}.kegT7{padding:0 48px}@media only screen and (max-width: 600px){.kegT7{padding:0 18px}}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"overrideBackground": "_9O1At",
	"overrideContent": "GFg6l",
	"backgroundColor": "DMvOM",
	"categoryHeaderBackground": "PtGX7",
	"appsCount": "_1pKhm",
	"apps-count-text": "IW0Oh",
	"appsCountText": "IW0Oh",
	"sort-by": "_0DSqY",
	"sortBy": "_0DSqY",
	"categoryContent": "kegT7"
};
export default ___CSS_LOADER_EXPORT___;
