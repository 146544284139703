// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/@wix/yoshi-style-dependencies/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Kjj19{padding:18px;height:100%;min-width:270px;transition:transform 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);background:white;box-sizing:border-box;border:solid 1px #dfe5eb;border-radius:8px;cursor:pointer}.Kjj19:hover{transform:translateY(-6px);box-shadow:0px 2px 12px rgba(0,6,36,0.16)}.Kjj19:hover .nThTv{text-decoration:underline}._661Au{padding:18px 24px;height:100%;min-width:270px;background:white;box-sizing:border-box;cursor:pointer}._661Au:hover{background:#f4f7ff}._661Au:hover .nThTv{text-decoration:underline}.B1bv5{width:calc(100% - 48px);border-bottom:solid 1px #dfe5eb;margin:0 24px}._7f2o8{height:100%}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"grid-app-box": "Kjj19",
	"gridAppBox": "Kjj19",
	"cta-button": "nThTv",
	"ctaButton": "nThTv",
	"list-app-box": "_661Au",
	"listAppBox": "_661Au",
	"list-app-box-divider": "B1bv5",
	"listAppBoxDivider": "B1bv5",
	"app-box-container": "_7f2o8",
	"appBoxContainer": "_7f2o8"
};
export default ___CSS_LOADER_EXPORT___;
