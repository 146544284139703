// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/@wix/yoshi-style-dependencies/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PpysZ{width:100%;height:527px;align-self:center;box-sizing:border-box}@media only screen and (max-width: 600px){.PpysZ{height:auto;align-self:center}}.N1KAE{width:100%;background-color:#f0f4f7;margin-top:39px}.N1KAE .Vppix{max-width:1254px;margin:0 auto;padding-left:48px;padding-right:48px}@media only screen and (max-width: 600px){.N1KAE .Vppix{padding-left:4vw;padding-right:4vw}}.PRaMA{margin-top:30px;margin-bottom:24px}@media only screen and (max-width: 600px){.PRaMA{margin-top:24px;margin-bottom:24px}}.op3at{display:flex;flex-direction:column;align-items:center}._7-ZgV{padding-top:12px !important}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "PpysZ",
	"carousel-container": "N1KAE",
	"carouselContainer": "N1KAE",
	"categories": "Vppix",
	"app-name": "PRaMA",
	"appName": "PRaMA",
	"empty-state-buttons": "op3at",
	"emptyStateButtons": "op3at",
	"contact-button": "_7-ZgV",
	"contactButton": "_7-ZgV"
};
export default ___CSS_LOADER_EXPORT___;
