// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/@wix/yoshi-style-dependencies/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".zocSZ{z-index:999;box-sizing:border-box;width:230px;text-align:left}.zocSZ [data-hook=\"side-bar\"]{overflow:visible}@media only screen and (max-width: 600px){.zocSZ{display:none}}.v9PXD{width:230px;text-align:left}.v9PXD [data-hook=\"side-bar\"]{overflow:visible}@media only screen and (max-width: 600px){.v9PXD{display:none}}.bhBkq{padding:18px}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"marketplaceMenuSA": "zocSZ",
	"marketplaceMenuSa": "zocSZ",
	"marketplaceMenu": "v9PXD",
	"search-menu": "bhBkq",
	"searchMenu": "bhBkq"
};
export default ___CSS_LOADER_EXPORT___;
