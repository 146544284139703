import * as React from 'react';
import App from './components/App';
import { ExperimentsProvider } from '@wix/wix-experiments-react';
import { configureScope } from '@sentry/browser';
import '@wix/design-system/styles.global.css';

class MarketplaceComp extends React.Component<any> {
  constructor(props) {
    super(props);

    configureScope(scope => {
      scope.setTag('component', 'app-market-panel');
    });

    console.log(
      `marketplace-editor-container/src/marketplace.tsx file - line 14 - ${Date.now()}`,
    );
  }

  render() {
    return (
      <div style={{ overflow: 'auto', height: '100%' }}>
        <ExperimentsProvider options={{ scope: 'app-market-editor' }}>
          <App {...this.props} />
        </ExperimentsProvider>
      </div>
    );
  }
}

export const Marketplace = {
  marketplace: MarketplaceComp,
};
