// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/@wix/yoshi-style-dependencies/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._0QxKK{background-color:white;width:calc(100%);position:sticky;top:60px;left:229px;z-index:8;border-top:1px solid gainsboro;border-bottom:1px solid gainsboro}.wfXh8{background-color:white;position:sticky;top:0;width:100%;z-index:8;border-top:1px solid gainsboro;border-bottom:1px solid gainsboro}._5z1jR{margin-left:48px}@media only screen and (max-width: 600px){._5z1jR{margin-left:19px}}._619ra{padding:12px 0px 12px 0px}.gXRpd{fill:white;margin-left:70px}.dc3yh{margin-left:5px}.DEySk{box-sizing:border-box;position:absolute;right:4vw;display:inline-flex;align-items:center}.DEySk .\\+rUZX{display:flex;align-items:center}.DEySk .\\+rUZX .z26rn{color:#60bc57;margin-right:3px}.VI-ib{margin-left:18px;min-width:120px}@media only screen and (max-width: 600px){.VI-ib{margin-bottom:5px;min-width:auto}}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"solution-page-header-container-SA": "_0QxKK",
	"solutionPageHeaderContainerSa": "_0QxKK",
	"solution-page-header-container": "wfXh8",
	"solutionPageHeaderContainer": "wfXh8",
	"header-back": "_5z1jR",
	"headerBack": "_5z1jR",
	"header-avatar": "_619ra",
	"headerAvatar": "_619ra",
	"header-back-btn": "gXRpd",
	"headerBackBtn": "gXRpd",
	"header-dev-link": "dc3yh",
	"headerDevLink": "dc3yh",
	"header-btn-and-pricing": "DEySk",
	"headerBtnAndPricing": "DEySk",
	"installed-container": "+rUZX",
	"installedContainer": "+rUZX",
	"installed-mark": "z26rn",
	"installedMark": "z26rn",
	"header-add-to-site-btn": "VI-ib",
	"headerAddToSiteBtn": "VI-ib"
};
export default ___CSS_LOADER_EXPORT___;
