// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/@wix/yoshi-style-dependencies/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PqjKu{position:relative}.oTvCs{top:2px;left:2px;width:36px;height:36px;border:solid 1px #DFE5EB;background:#ffffff;position:relative}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"images-stack": "PqjKu",
	"imagesStack": "PqjKu",
	"icon": "oTvCs"
};
export default ___CSS_LOADER_EXPORT___;
