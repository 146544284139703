// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/@wix/yoshi-style-dependencies/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GgiGH{position:absolute;left:50%;top:50%}.efgwb{height:100%}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loader-wrapper": "GgiGH",
	"loaderWrapper": "GgiGH",
	"font-upgrade": "efgwb",
	"fontUpgrade": "efgwb"
};
export default ___CSS_LOADER_EXPORT___;
