// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/@wix/yoshi-style-dependencies/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3zFmB{color:#60bc57;margin-right:4px}.NSMti{display:flex;align-items:center;white-space:unset !important;padding-bottom:2px;padding-top:1px}.unTSk{display:flex}.\\+km2w{display:flex}.\\+km2w :nth-child(1){margin-right:4px;flex-shrink:0}.\\+km2w :nth-child(2){flex-shrink:1}._5WhK0{display:flex;color:#32536a;align-items:flex-start}._5WhK0 svg{margin-right:4px;flex-shrink:0}._5WhK0 .T9-wD{padding-bottom:2px;padding-top:1px}._5WhK0 :nth-child(2){flex-shrink:1;word-break:break-word}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"installed-icon": "_3zFmB",
	"installedIcon": "_3zFmB",
	"centered-view": "NSMti",
	"centeredView": "NSMti",
	"flex-view": "unTSk",
	"flexView": "unTSk",
	"bundle-badge": "+km2w",
	"bundleBadge": "+km2w",
	"premium-badge": "_5WhK0",
	"premiumBadge": "_5WhK0",
	"premium-text": "T9-wD",
	"premiumText": "T9-wD"
};
export default ___CSS_LOADER_EXPORT___;
