// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/@wix/yoshi-style-dependencies/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".uyVDe{display:flex;flex-direction:column;box-sizing:border-box;width:100%;padding:24px;background:white;cursor:pointer;border-radius:8px;border:solid 1px #dfe5eb;top:0;position:relative;transition:transform 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94)}.uyVDe:hover{transform:translateY(-6px)}.n4w\\+6{display:flex;flex-direction:column;box-sizing:border-box;width:100%;padding:18px 24px;background:white;border-radius:8px;cursor:pointer;top:0;position:relative;transition:transform 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94)}.n4w\\+6:hover{transform:translateY(-6px)}.QZn0t{color:initial;text-decoration:none}.VXEmL{min-width:270px;max-width:419px;transition:transform 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94)}@media only screen and (max-width: 600px){.VXEmL{max-width:100%}}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"solution-box-wrapper-marketplace-layout": "uyVDe",
	"solutionBoxWrapperMarketplaceLayout": "uyVDe",
	"solution-box-wrapper-integrations-layout": "n4w+6",
	"solutionBoxWrapperIntegrationsLayout": "n4w+6",
	"box-link": "QZn0t",
	"boxLink": "QZn0t",
	"solution-box": "VXEmL",
	"solutionBox": "VXEmL"
};
export default ___CSS_LOADER_EXPORT___;
