// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/@wix/yoshi-style-dependencies/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".qkipU{width:100%;height:36px;display:flex;padding-bottom:9px;padding-top:9px;box-sizing:border-box;padding-left:20px}.qkipU:hover{background-color:#eaf7ff}.qkipU:active{background-color:#eaf7ff}.qkipU:focus{background-color:#eaf7ff;outline:none}.qkipU:last-child{border-bottom-left-radius:8px;border-bottom-right-radius:8px}.haR2E{width:100%;height:36px;display:flex;padding-bottom:9px;padding-top:9px;box-sizing:border-box;padding-left:20px}.haR2E:hover{background-color:#daeffe}.haR2E:active{background-color:#daeffe}.haR2E:focus{background-color:#daeffe;outline:none}._5NFfq{width:282px;height:18px;font-family:HelveticaNeueW01-55Roman;font-size:14px;font-weight:normal;font-stretch:normal;font-style:normal;line-height:1.29;letter-spacing:normal;color:#162d3d;align-self:end;padding-left:8px}.gOvLE{box-sizing:border-box;width:100%;height:54px;display:flex;box-sizing:border-box;padding:9px 18px}.gOvLE:hover{background-color:#daeffe}.gOvLE:active{background-color:#daeffe}.gOvLE:focus{background-color:#daeffe;outline:none}.YOte8{height:18px;font-family:HelveticaNeueW01-55Roman;font-weight:normal;font-stretch:normal;font-style:normal;line-height:1.29;letter-spacing:normal;color:#162d3d;padding-left:8px}.zH71L{width:30px;height:30px;border-radius:8px}._5LI6e{display:flex;flex-direction:column;justify-content:center;overflow:hidden}.tk-dd{display:flex;flex-direction:row;overflow:hidden;align-items:center;justify-content:space-between}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"default-suggestion-container": "qkipU",
	"defaultSuggestionContainer": "qkipU",
	"popular-suggestion-container": "haR2E",
	"popularSuggestionContainer": "haR2E",
	"default-suggestion-button": "_5NFfq",
	"defaultSuggestionButton": "_5NFfq",
	"suggestion-container": "gOvLE",
	"suggestionContainer": "gOvLE",
	"suggestion-button": "YOte8",
	"suggestionButton": "YOte8",
	"suggestion-icon": "zH71L",
	"suggestionIcon": "zH71L",
	"text-container": "_5LI6e",
	"textContainer": "_5LI6e",
	"wix-offering": "tk-dd",
	"wixOffering": "tk-dd"
};
export default ___CSS_LOADER_EXPORT___;
