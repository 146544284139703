// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/@wix/yoshi-style-dependencies/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2qPEq{width:85%;height:78px}@media only screen and (max-width: 600px){._2qPEq{margin-top:24px}}._2qPEq .TXyzt{display:inline-block;height:30px;margin-right:17px;margin-left:6px;vertical-align:top}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"subCategoriesSelector": "_2qPEq",
	"selectorsDivider": "TXyzt"
};
export default ___CSS_LOADER_EXPORT___;
