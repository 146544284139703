// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/@wix/yoshi-style-dependencies/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._8-srK{width:100%;height:36px;border:solid 1px #dfe5eb;background-color:#f0f4f7;box-sizing:border-box;display:flex}@media only screen and (max-width: 600px){._8-srK{width:calc(100vw + 25px);margin-left:-25px}}._3NJhE{padding-left:24px;margin-top:9px}@media only screen and (max-width: 600px){._3NJhE{padding-left:40px}}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"categories-suggestions-title": "_8-srK",
	"categoriesSuggestionsTitle": "_8-srK",
	"title-text": "_3NJhE",
	"titleText": "_3NJhE"
};
export default ___CSS_LOADER_EXPORT___;
