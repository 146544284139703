// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/@wix/yoshi-style-dependencies/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".KSlvo{margin-bottom:6px}@media only screen and (max-width: 600px){.KSlvo{padding-top:16px;margin-bottom:6px}}.EJThF{margin-top:0;margin-bottom:24px;font-size:18px !important}@media only screen and (max-width: 600px){display:none}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"categoryHeaderName": "KSlvo",
	"categoryHeaderDescription": "EJThF"
};
export default ___CSS_LOADER_EXPORT___;
