import * as React from 'react';
import * as PropTypes from 'prop-types';
import { createHttpClient, IHttpClient } from '@wix/http-client';
import s from './App.scss';
import {
  ClientSDK,
  Platform,
  IAppInstallParams,
} from '@wix/installation-manager-client-sdk';
import {
  IAppMarketParams,
  IMarketplaceProps,
  IRoute,
  Marketplace,
  Origin,
  Path,
  AppPageMode,
  INotificationsOptions,
} from '@wix/marketplace-component';
import {
  finishAppInstall,
  manageAppsMainButtonAction,
  openApp,
  openPackagePicker,
} from '../../helpers';
import {
  withExperiments,
  InjectedExperimentsProps,
} from '@wix/wix-experiments-react';
import { decodeJwt, fetchInstalledApps, isWixStudioSite } from '../../api';
import { create } from '@wix/fedops-logger';
import { BundleWebSolution } from '@wix/ambassador-marketplace/types';
import {
  ManagedApp,
  AppGroup,
} from '@wix/ambassador-devcenter-managedapps-v1-managed-app/types';
import webBiLogger from '@wix/web-bi-logger';
import { BiLogger, EssentialsContextProvider } from '@wix/app-market-core';
import { initI18nWithoutICU, i18n as i18nType } from '@wix/wix-i18n-config';

let clientSDK;

interface IERBConfig {
  topology: { marketplaceStaticsUrl: string; manageInstalledAppsUrl: string };
}

interface IAppProps extends Partial<InjectedExperimentsProps> {
  referralInfo?: string;
  metaSiteId: string;
  config: IERBConfig;
  locale: string;
  appMarketParams?: IAppMarketParams;
  appMarketHandlers: any;
  openAppDefId?: string;
  slug?: string;
  baseApiUrl?: string;
  openMarketOrigin?: string;
  isAppManagerOpen?: boolean;
  marketRoute?: string;
}

interface IAppState {
  currentRoute: IRoute;
  metaSiteId: string;
  saved?: boolean;
  editorType: string;
}

const parseAppMarketParams = async (
  appMarketParamsKey: IAppMarketParams,
  editorType: string,
) => {
  let appMarketParams;
  try {
    appMarketParams = await decodeJwt(appMarketParamsKey, editorType);
    if (appMarketParams && typeof appMarketParams.data === 'string') {
      return JSON.parse(appMarketParams.data);
    }
  } catch (e) {
    console.log('error in appMarketParams');
  }

  return appMarketParams;
};

class App extends React.Component<IAppProps, IAppState> {
  static propTypes = {
    t: PropTypes.func,
  };
  private readonly fedopsLogger: any;
  private readonly biLogger: BiLogger;
  private readonly i18n: i18nType;
  private readonly httpClient: IHttpClient;

  constructor(props) {
    super(props);

    console.log(
      `marketplace-editor-container/src/components/App/App.tsx file - line 85 - ${Date.now()}`,
    );

    this.httpClient = createHttpClient({
      getAppToken: () => {
        return props.appMarketHandlers.getDataByAppDefId(
          '22bef345-3c5b-4c18-b782-74d4085112ff',
        ).instance;
      },
    });

    clientSDK = new ClientSDK({
      platform: Platform.EDITOR,
      metaSiteId: props.metaSiteId,
      editorSDK: props.appMarketHandlers,
      httpClient: this.httpClient,
      onEditorFinishAdd: async (appid: string) =>
        finishAppInstall(
          appid,
          props?.experiments,
          props?.appMarketHandlers?.getDataByAppDefId,
          await this.getEditorType(),
        ),
    });

    this.fedopsLogger = create('marketplace-editor-container', {
      isWidgetLogger: true,
    });
    this.fedopsLogger.appLoadStarted();
    this.state = {
      metaSiteId: this.props.metaSiteId,
      currentRoute: null,
      editorType: '',
    };
    this.biLogger = webBiLogger.factory().logger();
    this.biLogger.updateDefaults({
      msid: props.metaSiteId,
      market: Origin.EDITOR,
    });
    this.i18n = initI18nWithoutICU({
      locale: this.props.locale,
    });
  }

  saveSiteAndUpdateMsidIfNeeded(callback?: any) {
    this.props.appMarketHandlers.save({}, (saveResult) => {
      const msid = saveResult ? saveResult.metaSiteId : this.state.metaSiteId;
      if (msid !== this.state.metaSiteId) {
        this.setState({ metaSiteId: msid, saved: true });
        clientSDK.setMetaSiteId(msid);
      } else {
        this.setState({ saved: true });
      }
      callback && callback(msid);
    });
  }

  async componentDidMount() {
    this.fedopsLogger.appLoaded();
    this.saveSiteAndUpdateMsidIfNeeded();
    const editorType = await this.getEditorType();
    this.setState({ ...this.state, editorType }, async () => {
      await this.setRoute();
    });

    if (
      !this.props.experiments?.enabled('specs.marketplace.includeFullstory')
    ) {
      // @ts-expect-error
      window.wixRecorder.addLabel('app-market');
      // @ts-expect-error
      window.wixRecorder.addLabel('app-market-editor');
    }
  }

  async getEditorType() {
    const baseApiUrl = this.props.baseApiUrl;
    const isEditorX = baseApiUrl && baseApiUrl.includes('editorx');
    const isWixStudio = await isWixStudioSite(
      this.props.appMarketHandlers.getDataByAppDefId,
      isEditorX,
    );
    return isEditorX ? 'EDITORX' : isWixStudio ? 'STUDIO' : 'EDITOR';
  }

  getMarketplaceProps(): IMarketplaceProps {
    const {
      locale,
      config,
      appMarketHandlers,
      experiments,
      baseApiUrl,
      isAppManagerOpen,
    } = this.props;
    return {
      goto: (route: IRoute) => {
        const router = document.querySelector(
          '[data-testid=marketplace-router]',
        );
        if (router && router.children && router.children[0]) {
          router.children[0].scrollIntoView();
        }

        this.setState({ currentRoute: { ...route, hideMenu: false } });
      },
      goToManageApps: () => {
        this.setState({ currentRoute: { path: Path.MANAGE_APPS } });
      },
      locale,
      metaSiteId: this.state.metaSiteId,
      baseApiUrl: baseApiUrl || 'https://editor.wix.com',
      origin: Origin.EDITOR,
      route: this.state.currentRoute,
      manageAppsStaticsUrl: `${config.topology.manageInstalledAppsUrl}/`,
      marketplaceStaticsUrl: `${config.topology.marketplaceStaticsUrl}/`,
      installApp: (params: IAppInstallParams) => {
        appMarketHandlers.save({}, (saveParams) => {
          if (this.state.metaSiteId !== (saveParams && saveParams.metaSiteId)) {
            this.setState({ metaSiteId: saveParams && saveParams.metaSiteId });
            clientSDK.setMetaSiteId(saveParams && saveParams.metaSiteId);
          }
          clientSDK.installApp(params);
        });
      },
      openBMInEditor: (path: string) => appMarketHandlers.openBMInEditor(path),
      openApp: (app: ManagedApp | BundleWebSolution, options) =>
        openApp(
          app,
          appMarketHandlers,
          this.state.metaSiteId,
          this.state.editorType,
          experiments,
          options,
        ),

      finishAppInstall: clientSDK.finishEditorInstallation,
      manageAppsMainButtonAction: (
        app: ManagedApp | BundleWebSolution,
        bundleApps: BundleWebSolution[],
        onInstallationSuccessCb: IAppInstallParams['onInstallationSuccess'],
      ) =>
        manageAppsMainButtonAction(
          app,
          bundleApps,
          appMarketHandlers,
          this.state.metaSiteId,
          this.state.editorType,
          (route) => this.setState({ currentRoute: route }),
          clientSDK.installApp,
          onInstallationSuccessCb,
          experiments,
        ),
      experimentsBag: experiments.all(),
      getAppInstance: (appDefID) =>
        appMarketHandlers.getDataByAppDefId(appDefID).instance,
      closeMarket: appMarketHandlers.closeAppMarket,
      isAppOnStage: (appDefinitionId) =>
        new Promise((resolve) => {
          appMarketHandlers.appIsInstalled(
            { params: { appDefinitionId } },
            (isOnStage) => resolve(isOnStage),
          );
        }),
      saveSite: () =>
        new Promise((resolve) => {
          this.saveSiteAndUpdateMsidIfNeeded(resolve);
        }),
      editorType: this.state.editorType,
      hideBusinessSolutionsInManageApps:
        isAppManagerOpen &&
        experiments.enabled('specs.marketplace.filter-business-solutions-apps'),
      countryCode:
        appMarketHandlers.getUserGeo && appMarketHandlers.getUserGeo(),
      showUserActionNotification: (options: INotificationsOptions) =>
        appMarketHandlers.showUserActionNotification &&
        appMarketHandlers.showUserActionNotification({
          ...options,
          ...{
            shouldTranslate: false,
            type:
              options.type.toLowerCase() === 'success' ? 'success' : 'error',
          },
        }),

      shouldUseSupportModal: true,
      unInstallApp: (appId) => appMarketHandlers.uninstallPlatformApp(appId),
      openPackagePicker: (
        app: ManagedApp,
        ppOrigin?: string,
        isAppInBundle?: boolean,
      ) =>
        openPackagePicker(
          app,
          appMarketHandlers.getDataByAppDefId,
          this.state.editorType,
          this.state.metaSiteId,
          ppOrigin,
        ),
    };
  }

  async setRoute() {
    if (this.props.appMarketParams || this.props.openAppDefId) {
      let route, shareId, appDefId, appId, version, Id, modal, customParams;
      if (this.props.appMarketParams) {
        const appMarketParamsJson = await parseAppMarketParams(
          this.props.appMarketParams,
          this.state.editorType,
        );
        ({ route, shareId, appDefId, appId, version, modal, customParams } =
          appMarketParamsJson);
        Id = appDefId || appId; // :(
      } else {
        Id = this.props.openAppDefId;
        version = '';
      }

      if (route === 'manageApps' && Id && modal) {
        this.setState({
          currentRoute: {
            path: Path.MANAGE_APPS,
            manageAppsDeepLinkData: { modal, appId },
            hideMenu: false,
          },
          metaSiteId: this.props.metaSiteId,
        });
      } else if (Id && customParams) {
        this.setState({
          currentRoute: {
            path: Path.OPEN_APP_WITH_PARAMS,
            slug: Id,
            version,
            hideMenu: true,
            openAppOptions: customParams,
          },
          metaSiteId: this.props.metaSiteId,
        });
      } else if (Id && !route) {
        this.setState({
          currentRoute: {
            path: Path.FINISH_SETUP,
            slug: Id,
            version,
            hideMenu: true,
          },
          metaSiteId: this.props.metaSiteId,
        });
      } else if (Id && shareId && route === 'shareApp') {
        this.setState({
          currentRoute: {
            path: Path.SHARE,
            shareAppData: { appDefId: Id, shareId, version },
            hideMenu: false,
          },
          metaSiteId: this.props.metaSiteId,
        });
      } else if (Id && route === 'testApp') {
        this.setState({
          currentRoute: {
            path: Path.WEB_SOLUTION,
            slug: Id,
            version,
            appPageMode: AppPageMode.TEST,
            languageCode: this.props.locale,
          },
          metaSiteId: this.props.metaSiteId,
        });
      } else if (Id && route === 'updateApp') {
        this.setState({
          currentRoute: {
            path: Path.FINISH_UPDATE,
            slug: Id,
            version,
            hideMenu: true,
          },
          metaSiteId: this.props.metaSiteId,
        });
      }
    } else if (this.props.marketRoute === 'manageApps') {
      this.setState({
        currentRoute: {
          path: Path.MANAGE_APPS,
          hideMenu: false,
        },
        metaSiteId: this.props.metaSiteId,
      });
    } else if (this.props.slug) {
      this.setState({
        currentRoute: {
          path: Path.WEB_SOLUTION,
          slug: this.props.slug,
          hideMenu: false,
          referral: this.props.openMarketOrigin,
        },
        metaSiteId: this.props.metaSiteId,
      });
    } else if (this.state.saved) {
      const installedApps =
        (await fetchInstalledApps(
          this.props.appMarketHandlers.getDataByAppDefId,
          this.props.locale,
          this.state.editorType,
        )) || [];
      const pendingCount = installedApps.filter(
        (app) => app.group === AppGroup.PENDING,
      ).length;

      if (pendingCount > 0) {
        this.setState({
          currentRoute: {
            path: Path.MANAGE_APPS,
            hideMenu: false,
          },
          metaSiteId: this.props.metaSiteId,
        });
      } else {
        this.setState({
          currentRoute: {
            path: Path.HOME,
            hideMenu: false,
          },
          metaSiteId: this.props.metaSiteId,
        });
      }
    } else {
      this.setState({
        currentRoute: {
          path: Path.HOME,
          hideMenu: false,
          ...(this?.props?.openMarketOrigin && {
            referral: this.props.openMarketOrigin,
          }),
        },
        metaSiteId: this.props.metaSiteId,
      });
    }
  }

  render() {
    console.log(
      `marketplace-editor-container/src/components/App/App.tsx file - line 371 - ${Date.now()}`,
    );
    const marketplaceProps = this.getMarketplaceProps();
    return (
      <div className={s.root}>
        {this.state.currentRoute &&
          this.state.saved &&
          this.state.editorType && (
            <EssentialsContextProvider
              essentials={{
                biLogger: this.biLogger,
                i18n: this.i18n,
                httpClient: this.httpClient,
                experiments: this.props.experiments,
              }}
            >
              <Marketplace {...marketplaceProps} />
            </EssentialsContextProvider>
          )}
      </div>
    );
  }
}

export default withExperiments(App);
