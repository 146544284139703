// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/@wix/yoshi-style-dependencies/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".mwLfb .ZCy3p{position:fixed;left:50%;top:50%}.mwLfb .cHS81{position:fixed;left:50%;top:50%}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"view-wrapper-container": "mwLfb",
	"viewWrapperContainer": "mwLfb",
	"fetch-loader": "ZCy3p",
	"fetchLoader": "ZCy3p",
	"error-loader": "cHS81",
	"errorLoader": "cHS81"
};
export default ___CSS_LOADER_EXPORT___;
