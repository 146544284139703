// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/@wix/yoshi-style-dependencies/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".-cN7z{z-index:0}.IVMI5{display:flex;flex-direction:column}.IVMI5 ._99erY{margin-top:18px;display:flex;justify-content:center}._31MFR{margin-top:30px;margin-bottom:15px;display:flex;justify-content:space-between;align-items:center;text-align:center}._31MFR .pLkQ1{width:40%}._4NuuO{min-width:-moz-max-content;min-width:max-content;padding:0 24px}.TB58M{display:flex;justify-content:center}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"empty-state-title": "-cN7z",
	"emptyStateTitle": "-cN7z",
	"add-app-box": "IVMI5",
	"addAppBox": "IVMI5",
	"add-app-box-button": "_99erY",
	"addAppBoxButton": "_99erY",
	"empty-state-divider": "_31MFR",
	"emptyStateDivider": "_31MFR",
	"divider-wrapper": "pLkQ1",
	"dividerWrapper": "pLkQ1",
	"empty-state-text": "_4NuuO",
	"emptyStateText": "_4NuuO",
	"recommended-grid-wrapper": "TB58M",
	"recommendedGridWrapper": "TB58M"
};
export default ___CSS_LOADER_EXPORT___;
