// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/@wix/yoshi-style-dependencies/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Amwb3{width:-moz-fit-content;width:fit-content}.w5fJL{width:800px}@media only screen and (max-width: 600px){.w5fJL{width:auto !important;font-size:14px !important;font-weight:normal;font-stretch:normal;font-style:normal;line-height:1.29 !important;letter-spacing:normal;color:#162d3d}}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sizeFix": "Amwb3",
	"collectionDescription": "w5fJL"
};
export default ___CSS_LOADER_EXPORT___;
