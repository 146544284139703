// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/@wix/yoshi-style-dependencies/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tJ9a5,.W6tDc{max-width:1248px;min-width:864px;margin:0 auto 33px;padding:0 48px}@media only screen and (max-width: 600px){.tJ9a5,.W6tDc{padding:0 4vw;min-width:initial;margin:0 auto}}.W6tDc{max-width:none}@media only screen and (max-width: 600px){.W6tDc{padding:0 4vw;min-width:initial;margin:0 auto}}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fluid-container": "tJ9a5",
	"fluidContainer": "tJ9a5",
	"fluid-container-wide": "W6tDc",
	"fluidContainerWide": "W6tDc"
};
export default ___CSS_LOADER_EXPORT___;
