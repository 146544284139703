// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/@wix/yoshi-style-dependencies/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Ru3hD .gTNII{display:none}.Ru3hD:hover{background-color:#eaf7ff;cursor:pointer}.Ru3hD:hover .gTNII{display:unset}.iM4he:hover{background-color:#eaf7ff;cursor:pointer}.iM4he:hover .gTNII{display:unset}.H64Pp{background-color:#c73b99 !important;border-color:#c73b99 !important}.H64Pp:hover{background-color:#e869be !important;border-color:#e869be !important}.Qo4qi{color:#c73b99 !important}.XamKO{min-width:-moz-max-content !important;min-width:max-content !important}.yLSMO{min-width:-moz-max-content !important;min-width:max-content !important;border-color:#c73b99 !important;color:#c73b99 !important}.yLSMO:hover{background-color:#e869be !important;border-color:#e869be !important;color:white !important}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"app-card-container": "Ru3hD",
	"appCardContainer": "Ru3hD",
	"delete": "gTNII",
	"app-row": "iM4he",
	"appRow": "iM4he",
	"holiday-color": "H64Pp",
	"holidayColor": "H64Pp",
	"sale-color": "Qo4qi",
	"saleColor": "Qo4qi",
	"upgrade-button-align": "XamKO",
	"upgradeButtonAlign": "XamKO",
	"upgrade-sale-button": "yLSMO",
	"upgradeSaleButton": "yLSMO"
};
export default ___CSS_LOADER_EXPORT___;
