// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/@wix/yoshi-style-dependencies/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._9OesW{width:18px;padding:2px 3px 2px 0}.WgfX7{margin-right:5px}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"star-icon": "_9OesW",
	"starIcon": "_9OesW",
	"rating": "WgfX7"
};
export default ___CSS_LOADER_EXPORT___;
