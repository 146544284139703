// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/@wix/yoshi-style-dependencies/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ElhYB{position:fixed;top:0;left:0;transform:translateY(-100vh);height:auto;width:100vw;background-color:white;transition:transform 300ms linear;z-index:10001;display:none}@media only screen and (max-width: 600px){.ElhYB{display:block}}.ElhYB .tgOyj{padding:20px 14px}.ElhYB .X3ae3{display:flex;justify-content:space-between;align-items:center;margin-bottom:18px}.ElhYB.u4vy4{transform:translateY(0)}.ElhYB .GXWV2{padding-left:26px}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mobile-search": "ElhYB",
	"mobileSearch": "ElhYB",
	"inner": "tgOyj",
	"top-section": "X3ae3",
	"topSection": "X3ae3",
	"open": "u4vy4",
	"topics-title": "GXWV2",
	"topicsTitle": "GXWV2"
};
export default ___CSS_LOADER_EXPORT___;
