// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/@wix/yoshi-style-dependencies/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Xxi4A{width:100%;max-width:1254px;margin:33px auto 0}@media only screen and (max-width: 600px){.Xxi4A{width:100%}}.Dzpfx{margin-top:9px;display:block}.vFh11{font-size:24px !important;line-height:30px !important}.GQZKX{font-size:24px !important;line-height:30px !important;margin-bottom:24px !important;margin-top:33px !important}.tP0H8{margin-top:24px;margin-bottom:54px;display:block}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section": "Xxi4A",
	"widget-wrapper": "Dzpfx",
	"widgetWrapper": "Dzpfx",
	"widget-title": "vFh11",
	"widgetTitle": "vFh11",
	"promo-heading": "GQZKX",
	"promoHeading": "GQZKX",
	"spotlightWrapper": "tP0H8"
};
export default ___CSS_LOADER_EXPORT___;
