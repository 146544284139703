// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/@wix/yoshi-style-dependencies/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".S80Pg .zSamN{margin-top:54px}.XHVVK{margin-top:27px;min-height:263px;max-height:263px;width:100%;margin-bottom:20px}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"info-layout": "S80Pg",
	"infoLayout": "S80Pg",
	"icon-avatar": "zSamN",
	"iconAvatar": "zSamN",
	"pricing-cta": "XHVVK",
	"pricingCta": "XHVVK"
};
export default ___CSS_LOADER_EXPORT___;
