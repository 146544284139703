// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/@wix/yoshi-style-dependencies/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3O0h\\+{display:flex;justify-content:space-between;margin:6px 0 30px 0}@media only screen and (max-width: 600px){._3O0h\\+{margin-top:0px}}._3O0h\\+ .FDl\\+o{margin-bottom:24px}@media only screen and (max-width: 600px){._3O0h\\+ .FDl\\+o{font-size:20px !important;line-height:24px !important;margin-bottom:8px}}._3O0h\\+ .yuKXF{width:58.33%}._3O0h\\+ .urPTi{width:33.33%}@media only screen and (max-width: 1024px){._3O0h\\+{flex-direction:column}._3O0h\\+ .yuKXF{width:100%}._3O0h\\+ .urPTi{width:100%}}._3O0h\\+ .Bx7wN{min-width:18px;margin-top:4px}._3O0h\\+ ._8UJo4{background-image:linear-gradient(to bottom, black 75%, white) !important;-webkit-background-clip:text;-webkit-text-fill-color:transparent}._3O0h\\+ .POTn1{border:1px solid #DFE5EB;border-radius:8px;box-sizing:border-box;margin-bottom:24px}@media only screen and (max-width: 600px){._3O0h\\+ .POTn1{margin-bottom:0px}}._3O0h\\+ .HCume{padding:18px 24px 0;border:1px solid gainsboro;margin-bottom:24px}._3O0h\\+ .PDLuj{margin:6px 6px 0px 0px}._3O0h\\+ .ux5Zy{margin-bottom:30px}._3O0h\\+ .zl0YA{margin-top:24px;margin-bottom:18px;width:100%}._3O0h\\+ .xn1\\+x{margin-left:1px;display:block}._3O0h\\+ .wN-3y{margin-bottom:18px}._3O0h\\+ .zLhCy{margin-top:18px;margin-bottom:36px}._3O0h\\+ .WxJC8{margin-top:12px;margin-bottom:30px}.d1YwJ{visibility:hidden;height:0px}.EYtib{display:flex}.EYtib :nth-child(1){margin-right:6px}.XLzro{display:block}._52lf3{margin-top:8px}.vcPq-{padding:24px 24px 18px 24px}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"solutionOverViewContainer": "_3O0h+",
	"descriptionHeader": "FDl+o",
	"descriptionContent": "yuKXF",
	"solutionAppOverviewData": "urPTi",
	"overViewCheckMark": "Bx7wN",
	"longDescription": "_8UJo4",
	"sideCardContainer": "POTn1",
	"tagBoxContainer": "HCume",
	"solutionTag": "PDLuj",
	"descriptionBenefitsContainer": "ux5Zy",
	"devCardLinkContainerDivider": "zl0YA",
	"tagBoxTitle": "xn1+x",
	"tagBoxSection": "wN-3y",
	"solutionDemoLink": "zLhCy",
	"readMoreBtn": "WxJC8",
	"hiddenText": "d1YwJ",
	"availabilityTitle": "EYtib",
	"appLanguages": "XLzro",
	"customizeLang": "_52lf3",
	"devCardBy": "vcPq-"
};
export default ___CSS_LOADER_EXPORT___;
