// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/@wix/yoshi-style-dependencies/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dIeux{z-index:1000000;position:absolute}.LH\\+Nc{overflow:hidden;width:402px;height:180px;border-radius:8px;box-shadow:0 8px 8px 0 rgba(22,45,61,0.12),0 3px 24px 0 rgba(22,45,61,0.18);background-color:#ffffff;cursor:pointer}@media only screen and (max-width: 600px){.LH\\+Nc{width:100%}}.h8rY1{overflow:hidden;width:546px;height:180px;border-radius:8px;box-shadow:0 8px 8px 0 rgba(22,45,61,0.12),0 3px 24px 0 rgba(22,45,61,0.18);background-color:#ffffff;cursor:pointer}@media only screen and (max-width: 600px){.h8rY1{width:100%}}.hHsZV{width:300px;height:18px;font-family:HelveticaNeueW01-55Roman;font-size:14px;font-weight:normal;font-stretch:normal;font-style:normal;line-height:1.29;letter-spacing:normal;color:#7a92a5;padding-top:12px;padding-bottom:6px;padding-left:20px}.upZqf{width:402px;overflow:auto;border-radius:8px;box-shadow:0 8px 8px 0 rgba(22,45,61,0.12),0 3px 24px 0 rgba(22,45,61,0.18);background-color:#ffffff;cursor:pointer;box-sizing:border-box;z-index:1000000}@media only screen and (max-width: 600px){.upZqf{width:100%}}.upZqf::-webkit-scrollbar{background-color:white;border-radius:10px}.upZqf::-webkit-scrollbar-thumb{background-color:#c1e4fe;background-clip:content-box;border-radius:10px;border:5px solid transparent;height:72px;overflow:auto}.Bsqbm{width:546px;overflow:auto;border-radius:8px;box-shadow:0 8px 8px 0 rgba(22,45,61,0.12),0 3px 24px 0 rgba(22,45,61,0.18);background-color:#ffffff;cursor:pointer;box-sizing:border-box;z-index:1000000}@media only screen and (max-width: 600px){.Bsqbm{width:100%}}.Bsqbm::-webkit-scrollbar{background-color:white;border-radius:10px}.Bsqbm::-webkit-scrollbar-thumb{background-color:#c1e4fe;background-clip:content-box;border-radius:10px;border:5px solid transparent;height:72px;overflow:auto}.dtOOz .LH\\+Nc{width:326px}.dtOOz .upZqf{width:326px}@media only screen and (max-width: 1574px){.v\\+Yzd{right:48px}}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"suggestions-wrapper": "dIeux",
	"suggestionsWrapper": "dIeux",
	"default-suggestions-container": "LH+Nc",
	"defaultSuggestionsContainer": "LH+Nc",
	"default-suggestions-big-container": "h8rY1",
	"defaultSuggestionsBigContainer": "h8rY1",
	"default-suggestions-title": "hHsZV",
	"defaultSuggestionsTitle": "hHsZV",
	"suggestions-container": "upZqf",
	"suggestionsContainer": "upZqf",
	"suggestions-container-big": "Bsqbm",
	"suggestionsContainerBig": "Bsqbm",
	"is-in-header": "dtOOz",
	"isInHeader": "dtOOz",
	"is-in-search-page": "v+Yzd",
	"isInSearchPage": "v+Yzd"
};
export default ___CSS_LOADER_EXPORT___;
