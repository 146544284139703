// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/@wix/yoshi-style-dependencies/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".doifv{margin-bottom:52px}.doifv .ph0Dh{margin-bottom:9px}@media only screen and (max-width: 600px){.doifv .ph0Dh{font-size:20px !important}}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"suggestionsSection": "doifv",
	"suggestionsTitle": "ph0Dh"
};
export default ___CSS_LOADER_EXPORT___;
