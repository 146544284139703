// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../node_modules/@wix/yoshi-style-dependencies/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".eq-ke{padding:21px 21px;display:flex;flex-wrap:wrap;min-width:237px;height:396px;flex-direction:column;align-items:flex-start}.eq-ke .cdWa6{padding:9px 9px !important;width:-moz-fit-content !important;width:fit-content !important}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popover-content-container": "eq-ke",
	"popoverContentContainer": "eq-ke",
	"language": "cdWa6"
};
export default ___CSS_LOADER_EXPORT___;
