// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/@wix/yoshi-style-dependencies/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GKMR8 .uT5C0{min-width:80px !important;inline-size:-moz-max-content;inline-size:max-content}@media only screen and (max-width: 600px){.GKMR8{display:none !important}}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"notLoggedIn": "GKMR8",
	"loginButton": "uT5C0"
};
export default ___CSS_LOADER_EXPORT___;
